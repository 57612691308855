import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { API_URL } from './../Global'
import Chaldean from './Chaldean';
import ChaldeanGrid from './ChaldeanGrid';
import LoshuGrid from './LoshuGrid'
import LoshuMissingh from './LoshuMissingh';
import LoshuPlane from './LoshuPlane';
import LoshuVastu from './LoshuVastu';
import Marriage from './Marriage';
import Info from './Info';
import './print.css';
import GaneshJi from '../GaneshJi.png';
import maa from '../maa.png';
import YourNumbers from './YourNumbers';
import ChaldeanYourNumbers from './ChaldeanYourNumber';
import * as Numerology from './calc/Numerology';
import ChaldeanNameAnalysis from './ChaldeanNameAnalysis';
import Health from './Health';
import PythagoreanCareer from './PythagoreanCareer';
import Personality from './Personality';
import YearAnalysis from './YearAnalysis';
import ImportantYears from './ImportantYears';
import PersonalDayMonthYear from './PersonalDayMonthYear';
import SmallArrow from './SmallArrow';
import PythagoreanName from './PythagoreanName';
import Dasha from './Dasha';
import Compatibility from './Compatibility';
import NumerologistAdvice from './NumerologistAdvice';
import { useSelector } from 'react-redux';
import NumberAnalysis from './NumberAnalysis';
import HeartExpressionPersonality from './HeartExpressionPersonality';
const mon=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const getImage=()=>{
    let logo = API_URL+"user"+sessionStorage.getItem("logo").replace(".", "");
    console.log(logo);
    return logo;
}
const PredData=React.forwardRef((props,ref)=>{
    const [name, setName]=useState('');
    const [address, setAddress]=useState('');
    const [contact, setContact]=useState('');
    const [email, setEmail]=useState('');
    const [uurl, setUurl]=useState('');
    useEffect(()=>{
        fetchUser();
    },[]);
    const fetchUser=()=>{
        axios.get(API_URL+'user/readUser.php?uid='+sessionStorage.getItem('uid'))
.then(response => response.data)
.then(data=>{
    console.log(data);
    setName(data[0].name);
    setAddress(data[0].address);
    setContact(data[0].contact);
    setEmail(data[0].email);
    setUurl(data[0].url);
})
.catch(error=>console.log(error));
    }
            const native=useSelector((state)=>state.nativeReducer);
            return <div className="printdiv" ref={ref}>
                <div className="divHeader row">
                    <div className="col-xs-6">{localStorage.getItem('fname')+" "+localStorage.getItem('mname')+" "+localStorage.getItem('lname')}</div>
                    <div className="col-xs-6" style={{textAlign:'right'}}>{new Date(localStorage.getItem('dob')).getDate()+"-"+mon[new Date(localStorage.getItem('dob')).getMonth()]+"-"+new Date(localStorage.getItem('dob')).getFullYear()}</div>
                <p className="clearfix"></p>
                </div>
                <div className="divFooter row">
                    <div className="col-xs-6">{name}<br />{address}</div>
                    <div className="col-xs-6" style={{textAlign:'left'}}>Contact: {contact}<br />Email: {email}<br />Url: {uurl}</div>
                <p className="clearfix"></p>
                </div>
                <div className='divData'>
                <center>
                    <img src={getImage()} style={{width: 200}} alt="Logo" /><br /><br />
                    <img src={maa} alt="Jai Maa Saraswati" />
                
                </center>
                <Info />
            
                {props.print.includes(1) && <div><p className="pagebreak"></p><Personality /></div>}
                {props.print.includes(21) && <div><p className="pagebreak"></p><HeartExpressionPersonality /></div>}
            
            {props.print.includes(2) && <div><p className="pagebreak"></p><YourNumbers /></div>}
            
            {props.print.includes(3) && <div><p className="pagebreak"></p><PythagoreanName /></div>}
            {/* <p className="pagebreak"></p>
            <p>
                    <h3 className="heading">Your Name Analysis</h3>
                    <div className='row'>
                    <div className='col-md-4'>{localStorage.getItem('fname_correction').split(",").map(x=>x)}</div>
                    <div className='col-md-4'>{localStorage.getItem('mname_correction').split(",").map(x=>x)}</div>
                    <div className='col-md-4'>{localStorage.getItem('lname_correction').split(",").map(x=>x)}</div>
                    </div>
                </p> */}
                
                {props.print.includes(4) && <div><p className="pagebreak"></p><ImportantYears /></div>}
                
                {props.print.includes(5) && <div><p className="pagebreak"></p><ChaldeanGrid /></div> }
            
            {props.print.includes(6) && <div><p className="pagebreak"></p><ChaldeanNameAnalysis /></div>}
            
            {props.print.includes(7) && <div><p className="pagebreak"></p><PersonalDayMonthYear dd={new Date(native.dob).getDate().toString()} mm={new Date(native.dob).getMonth().toString()} year={new Date(native.dob).getFullYear().toString()} /></div> }
            
            {props.print.includes(8) && <div><p className="pagebreak"></p><ChaldeanYourNumbers /></div> }
            
            {props.print.includes(9) && <div><p className="pagebreak"></p><LoshuGrid /></div>}
            
            {props.print.includes(10) && <div><p className="pagebreak"></p><LoshuMissingh /></div>}
            
            {props.print.includes(11) && <div><p className="pagebreak"></p><SmallArrow /></div>}
            
            {props.print.includes(12) && <div><p className="pagebreak"></p><LoshuVastu /></div>}
            
            {props.print.includes(13) && <div><p className="pagebreak"></p><LoshuPlane /></div>}
            {props.print.includes(14) && <div><p className="pagebreak"></p><Dasha /></div>}
            {props.print.includes(15) && <div><p className="pagebreak"></p><Health /></div>}
            {props.print.includes(16) && <div><p className="pagebreak"></p>
            {localStorage.getItem('mobileNumber')!==null && localStorage.getItem('mobileNumber').split(",").map(x=>{
                // <p>{x}<span> {Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.singleDigit(x))]}</span></p>
                <p>{x}</p>
            })}
            </div>}
            {props.print.includes(17) && <div><p className="pagebreak"></p><PythagoreanCareer /></div>}
            {props.print.includes(18) && <div><p className="pagebreak"></p><Marriage /></div>}
            {props.print.includes(19) && <div><p className="pagebreak"></p><Compatibility /></div>}
            {props.print.includes(20) && <div><p className="pagebreak"></p><p><h5 className='heading'>Numerologist Advice: </h5>{localStorage.getItem('advice')}</p></div>}
            
            </div>
            </div>
}
        )
export default PredData;