import Store from '../store/Store';
const Id=(id:any)=>{
    return {type:'ID', id}
}
const Dob=(dob:any)=>{
    return {type:'DOB', dob}
}
const Fname=(fname:string)=>{
    return {type:'FNAME', fname}
}
const Mname=(mname:string)=>{
    return {type:'MNAME', mname}
}
const Lname=(lname:string)=>{
    return {type:'LNAME', lname}
}
const Gender=(gender:string)=>{
    return {type:'GENDER', gender}
}
const Mother=(mother:string)=>{
    return {type:'MOTHER', mother}
}
const Father=(father:string)=>{
    return {type:'FATHER', father}
}
const Contact=(contact:string)=>{
    return {type:'CONTACT', contact}
}
const Email=(email:string)=>{
    return {type:'EMAIL', email}
}
const Address=(address:string)=>{
    return {type:'ADDRESS', address}
}
const Native=(native:boolean)=>{
    return {type:'NATIVE', native}
}
const Advice=(advice:string)=>{
    return {type:'ADVICE', advice}
}
export {Id, Dob, Fname, Mname, Lname,Gender, Native, Advice}