import React, {useState} from 'react'
import * as Numerology from './calc/Numerology'

function NumberAnalysis() {
    const [mobile1, setMobile1]=useState<string>('');
    const [mobile2, setMobile2]=useState<string>('');
    const [mobile3, setMobile3]=useState<string>('');
    const [mobile4, setMobile4]=useState<string>('');
    const [mobile5, setMobile5]=useState<string>('');
    const [mob1, setMob1]=useState<number[]>([0,0]);
    const [mob2, setMob2]=useState<number[]>([0,0]);
    const [mob3, setMob3]=useState<number[]>([0,0]);
    const [mob4, setMob4]=useState<number[]>([0,0]);
    const [mob5, setMob5]=useState<number[]>([0,0]);
    const setMobile=()=>{
        localStorage.removeItem('mobileNumber');
        localStorage.setItem('mobileNumber',mobile1+","+mobile2+","+mobile3+","+mobile4+","+mobile5);
    }
    return (
        <div>
            <h2>Mobile Number Analysis <small>(10 digits without 0 or +91)</small></h2>
            <div className="info preds">
                <p></p>
                <div className="well"><input type="number" value={mobile1} onChange={(e)=>{setMobile1(e.target.value);setMobile();setMob1(Numerology.singleDigitMobile(Number(e.target.value)));}} /> <span className="alert alert-info">{mob1[0]+'/'+mob1[1]}</span>{mobile1.split('').length===10 && <span> {Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.singleDigit(mobile1))]==="Favourable" && Numerology.Suitable[Numerology.getSuitable(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),(new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1], Numerology.singleDigit(mobile1))]==="Favourable" ? "Favourable" : "Avoidable"}</span>}</div>
                <div className="well"><input type="number" value={mobile2} onChange={(e)=>{setMobile2(e.target.value);setMobile();setMob2(Numerology.singleDigitMobile(Number(e.target.value)));}} /> <span className="alert alert-info">{mob2[0]+'/'+mob2[1]}</span>{mobile2.split('').length===10 && <span> {Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.singleDigit(mobile2))]==="Favourable" && Numerology.Suitable[Numerology.getSuitable(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),(new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1], Numerology.singleDigit(mobile2))]==="Favourable" ? "Favourable" : "Avoidable"}</span>}</div>
                <div className="well"><input type="number" value={mobile3} onChange={(e)=>{setMobile3(e.target.value);setMobile();setMob3(Numerology.singleDigitMobile(Number(e.target.value)));}} /> <span className="alert alert-info">{mob3[0]+'/'+mob3[1]}</span>{mobile3.split('').length===10 && <span> {Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.singleDigit(mobile3))]==="Favourable" && Numerology.Suitable[Numerology.getSuitable(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),(new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1], Numerology.singleDigit(mobile3))]==="Favourable" ? "Favourable" : "Avoidable"}</span>}</div>
                <div className="well"><input type="number" value={mobile4} onChange={(e)=>{setMobile4(e.target.value);setMobile();setMob4(Numerology.singleDigitMobile(Number(e.target.value)));}} /> <span className="alert alert-info">{mob4[0]+'/'+mob4[1]}</span>{mobile4.split('').length===10 && <span> {Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.singleDigit(mobile4))]==="Favourable" && Numerology.Suitable[Numerology.getSuitable(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),(new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1], Numerology.singleDigit(mobile4))]==="Favourable" ? "Favourable" : "Avoidable"}</span>}</div>
                <div className="well"><input type="number" value={mobile5} onChange={(e)=>{setMobile5(e.target.value);setMobile();setMob5(Numerology.singleDigitMobile(Number(e.target.value)));}} /> <span className="alert alert-info">{mob5[0]+'/'+mob5[1]}</span>{mobile5.split('').length===10 && <span> {Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.singleDigit(mobile5))]==="Favourable" && Numerology.Suitable[Numerology.getSuitable(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),(new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1], Numerology.singleDigit(mobile5))]==="Favourable" ? "Favourable" : "Avoidable"}</span>}</div>
                <p></p>
            </div>
        </div>
    )
}

export default NumberAnalysis;