import React from 'react'

function PredPrint(props:any) {
    return (
        <div>
            {props.number!==null && <h3 className="predhead">{props.missing ? props.number+" is missing in your chart." : "Prediction for number"+ props.number} </h3>}
            <span>{props.prediction}</span>
        </div>
    )
}

export default PredPrint
