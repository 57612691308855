import React from 'react'
import * as Numerology from './calc/Numerology';
function Personality() {
    return (
        <div>
            <h3 className="heading">Personality</h3>
            <div className="info preds">
                <h5 className="col-md-2">Birthday Number: {Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1]}</h5>
                <h5 className="col-md-2">Lifepath Number: {Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1]}</h5>
            <h5 className="col-md-2">Expression Number: {Numerology.expressionNumber(localStorage.fname,localStorage.mname,localStorage.lname)[1]}</h5>
                <h5 className="col-md-2">Heart Desire Number: {Numerology.heartDesireNumber(localStorage.fname,localStorage.mname,localStorage.lname)[1]}</h5>
                <h5 className="col-md-2">Personality Number: {Numerology.personalityNumber(localStorage.fname,localStorage.mname,localStorage.lname)[1]}</h5>
                <br /><hr />
            As per <strong>Birthday</strong> and <strong>Lifepath</strong> number<br /><br/>
            <p>
                <table className="table table-stripped table-bordered">
                    <thead>
                        <tr>
                            <th>Based on</th>
                            <th>Lucky Dates</th>
                            <th>Lucky Days</th>
                            <th>Lucky Colours</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Birthday Number</td>
                            <td>{Numerology.luckyDates(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1])}</td>
                            <td>{Numerology.luckyDays(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1])}</td>
                            <td>{Numerology.luckyColours(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1])}</td>
                        </tr>
                        <tr>
                            <td>Lifepath Number</td>
                            <td>{Numerology.luckyDates(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1])}</td>
                            <td>{Numerology.luckyDays(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1])}</td>
                            <td>{Numerology.luckyColours(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1])}</td>
                        </tr>
                    </tbody>
                </table>
            {Numerology.getPythagoreanCharacteristics(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1].toString())}
            {Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[0]!==0 && <div>{Numerology.getPythagoreanCharacteristics(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[0].toString())}<br /></div>}
            {Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1] !== Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1] && <div>{Numerology.getPythagoreanCharacteristics(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1].toString())}<br /></div>}
            {Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[0]!==0 && <div>{Numerology.getPythagoreanCharacteristics(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[0].toString())}<br /></div>}
            {Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1]===Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1] && Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1]<3 && <div>{Numerology.getPythagoreanCharacteristics(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1].toString()+Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1])}<br /></div>}
            {/* {Numerology.getPythagoreanCombo(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1].toString(), Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1].toString())} */}
            </p>
            </div>
        </div>
    )
}

export default Personality
