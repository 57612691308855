import React, {useState} from 'react'
import Header from './components/Header';
import Footer from './components/Footer';
import { API_URL } from './Global';
function NewUser() {
    const [name, setName]=useState('');
    const [contact, setContact]=useState('');
    const [email, setEmail]=useState('');
    const [password, setPassword]=useState('');
    const [cpassword, setCPassword]=useState('');
    const handleSubmit=()=>{
        if(password===cpassword){
        fetch(API_URL+'user/create.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify({"name":name,"contact":contact,"email":email,"password":password})
})
.then((response)=>response.data)
.then((data)=>alert("User Created..."))
.catch(error=>console.log(error));
        }else{
            alert("Password and Confirm Password must match.")
        }
    }
    return (
        <div style={{backgroundColor:'#fff', height:'100vh'}}>
            <Header />

            <div className="login-box" style={{marginTop:-30}}>
            <div className="login-box-body">
                <h3>New User</h3>
                <div className="info preds">
                    <form method="post" onSubmit={(e)=>{e.preventDefault(); handleSubmit()}}>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Contact</label>
                            <input type="text" value={contact} onChange={(e)=>setContact(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input type="password" value={cpassword} onChange={(e)=>setCPassword(e.target.value)} className="form-control" />
                        </div>
                        <input type="submit" value="Create" />
                    </form>
                </div>
                </div>
                </div>
            <Footer />
        </div>
    )
}

export default NewUser
