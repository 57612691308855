import React, {useState} from 'react'
import * as Numerology from './calc/Numerology' 
import {useSelector} from 'react-redux';
import Popup from './Popup';
// import * as Action from './actions/Native';
function YourNumbers() {

    const [isSeen, setIsSeen]=useState(false);
    const [contents, setContents]=useState("");
    const native=useSelector((state:any)=>state.nativeReducer);
    return (
        <>
        <h3 className="heading">Your Numbers</h3>
        <div className="row info preds">
        <div className="col-xs-6"><span className="numhead">Birthdate Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('birthdate')); setIsSeen(true);}}></i>: {Numerology.getLifePathNumber(new Date(native.dob).getDate())[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Life Path Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('lifepath')); setIsSeen(true);}}></i>: {Numerology.getLifePathNumber(new Date(native.dob).getDate(),new Date(native.dob).getMonth()+1,new Date(native.dob).getFullYear())[1]}</span></div>            
            <div className="col-xs-6"><span className="numhead">Expression Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('expression')); setIsSeen(true);}}></i>: {Numerology.expressionNumber(native.fname,native.mname,native.lname)[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Heart Desire Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('heartdesire')); setIsSeen(true);}}></i>: {Numerology.heartDesireNumber(native.fname,native.mname,native.lname)[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Personality Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('personality')); setIsSeen(true);}}></i>: {Numerology.personalityNumber(native.fname,native.mname,native.lname)[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Karmic Lesson Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmiclesson')); setIsSeen(true);}}></i>: {Numerology.getKarmicLessonNumber(native.fname+native.mname+native.lname).filter((x:any)=>x!==null).join(', ')}</span></div>
            <div className="col-xs-6"><span className="numhead">Karmic Debt Lifepath Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtlifepath')); setIsSeen(true);}}></i>: {Numerology.getLifePathNumber(new Date(native.dob).getDate(),new Date(native.dob).getMonth()+1,new Date(native.dob).getFullYear())[0]}</span></div>
            <div className="col-xs-6"><span className="numhead">Karmic Debt Birthday Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtbirthdate')); setIsSeen(true);}}></i>: {Numerology.getLifePathNumber(new Date(native.dob).getDate())[0]}</span></div>
            <div className="col-xs-6"><span className="numhead">Karmic Debt Expression Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtexpression')); setIsSeen(true);}}></i>: {Numerology.expressionNumber(native.fname,native.mname,native.lname)[0]}</span></div>
            <div className="col-xs-6"><span className="numhead">Karmic Debt Heart Desire Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtheartdesire')); setIsSeen(true);}}></i>: {Numerology.heartDesireNumber(native.fname,native.mname,native.lname)[0]}</span></div>
            <div className="col-xs-6"><span className="numhead">Karmic Debt Personality Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtpersonality')); setIsSeen(true);}}></i>: {Numerology.personalityNumber(native.fname,native.mname,native.lname)[0]}</span></div>
            <div className="col-xs-6"><span className="numhead">Hidden Passion Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('hiddenpassion')); setIsSeen(true);}}></i>: {Numerology.hiddenPassionNumber()}</span></div>
            <div className="col-xs-6"><span className="numhead">Compound Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('compound')); setIsSeen(true);}}></i>: {Numerology.getCompoundNumber(native.fname+native.mname+native.lname)}</span></div>
            <div className="col-xs-6"><span className="numhead">Total Alphabet Count <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('totalalphabet')); setIsSeen(true);}}></i>: {Numerology.getAlphaCountNumber(native.fname+native.mname+native.lname)}</span></div>
            <div className="col-xs-6"><span className="numhead">First Name Alphabet Count <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('firstnamealphabet')); setIsSeen(true);}}></i>: {Numerology.getAlphaCountNumber(native.fname)}</span></div>
            <div className="col-xs-6"><span className="numhead">First Name Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('firstname')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getCompoundNumber(native.fname))[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">First Name Compound Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('firstnamecompound')); setIsSeen(true);}}></i>: {Numerology.getCompoundNumber(native.fname)}</span></div>
            <div className="col-xs-6"><span className="numhead">Maturity Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('maturity')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getLifePathNumber(new Date(native.dob).getDate())[1]+Numerology.expressionNumber(native.fname,native.mname,native.lname)[1])[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Balance Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('balance')); setIsSeen(true);}}></i>: {Numerology.getCompoundNumber(native.fname.split('')[0]+native.mname.split('')[0]+native.lname.split('')[0])}</span></div>
            <div className="col-xs-6"><span className="numhead">Subconscious Self Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('subconsciousself')); setIsSeen(true);}}></i>: {}</span></div>
            <div className="col-xs-6"><span className="numhead">Rational Thought Number<i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('rationalthought')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getBirthdayNumber(new Date(native.dob).getDate())[1]+Numerology.getCompoundNumber(native.fname))[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Birth Month Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('birthmonth')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getBirthdayNumber(new Date(native.dob).getMonth()+1)[1])}</span></div>
            <div className="col-xs-6"><span className="numhead">Year Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('year')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getBirthdayNumber(new Date(native.dob).getFullYear())[1])}</span></div>
            <div className="col-xs-6"><span className="numhead">Sun Sign (Western) <i className="glyphicon glyphicon-info-sign"></i>: {Numerology.getZodiacWestern(native.dob)}</span></div>
            <div className="col-xs-6"><span className="numhead">Sun Sign (Eastern) <i className="glyphicon glyphicon-info-sign"></i>: {Numerology.getZodiac(native.dob)}</span></div>
            <div className="col-xs-6"><span className="numhead">Corner Stone <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('cornerstone')); setIsSeen(true);}}></i>: {localStorage.getItem('fname') && Numerology.pythagoreanCalc(localStorage.getItem('fname')?.split("")[0])[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Capstone <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('capstone')); setIsSeen(true);}}></i>: {Numerology.getCapstone()}</span></div>
            <div className="col-xs-6"><span className="numhead">First Vowel <i className="glyphicon glyphicon-info-sign"></i>: {Numerology.getFirstVowel()}</span></div>
        </div>
        {isSeen ? <Popup close={()=>setIsSeen(false)} contents={contents} /> : null}
        </>
    )
}

export default YourNumbers
