import React, {useState} from 'react';
import '../assets/css/bootstrap/css/bootstrap.min.css';
// import '../assets/font-awesome/css/font-awesome.min.css';
import '../assets/css/style1.css';
// import '../assets/js/jquery.min.js';
import '../assets/css/morris/morris.css';
import '../assets/css/jvectormap/jquery-jvectormap-1.2.2.css';
// import '../assets/css/datepicker/datepicker3.css';
// import '../assets/css/daterangepicker/daterangepicker-bs3.css';
// import '../assets/css/bootstrap-wysihtml5/bootstrap3-wysihtml5.min.css';
// import 'https://cdn.syncfusion.com/ej2/material.css';
import '../assets/css/AdminLTE.css';
import './Header.css';
import logo from '../images/logo.png';
import Menu from './Menu';
import Native from './Native';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
function Header() {
    const [l, setL]=useState("english");
    return (
        <React.Fragment>
        <header className="header">
      
      
      <nav className="navbar navbar-static-top" role="navigation">
      <Link to="/" className="logo">
          
          <img src={logo} height="50" />
      </Link>
          <a href="#" className="navbar-btn sidebar-toggle" style={{paddingLeft:0}} data-toggle="offcanvas" role="button">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
          </a>
          {sessionStorage.getItem('uid')!==null ?
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
            {sessionStorage.getItem('uid')!==null && sessionStorage.getItem('uid')==="1" && <li className="dropdown user-menu"><Link to="/newuser"><i className="glyphicon glyphicon-user"></i> New User</Link></li>}
            {sessionStorage.getItem('uid')!==null && <li className="dropdown user-menu"><Link to="/compatibility"><i className="glyphicon glyphicon-refresh"></i> Compatibility</Link></li>}
            {sessionStorage.getItem('uid')!==null && <li className="dropdown user-menu"><Link to="/"><i className="glyphicon glyphicon-search"></i> Prediction</Link></li>}
            {sessionStorage.getItem('uid')!==null && <li className="dropdown user-menu"><Link to="/name"><i className="glyphicon glyphicon-ok"></i> Name Correction</Link></li>}
            {sessionStorage.getItem('uid')!==null && sessionStorage.getItem('uid')==="1" && <li className="dropdown user-menu"><Link to="/admin"><i className="glyphicon glyphicon-ok"></i> Activate User</Link></li>}
                <li className="dropdown user-menu">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span><i className="glyphicon glyphicon"></i> Language <i className="caret"></i></span>
                  </a>
                  <ul className="dropdown-menu">
                       
                          <div className="bg-light-blue">
                      {/* <img src="#" alt="login" width="100%" className="" /> */}
                      </div>
                      
                      <li className="user-footer">

                          
                              {/* <Link to="/profile" className="btn btn-default btn-flat">profile</Link> */}
                              <button onClick={()=>{sessionStorage.setItem('language', 'english'); window.location.href=window.location.origin;}} className="btn btn-default btn-flat">English</button>
                              <button onClick={()=>{sessionStorage.setItem('language', 'hindi'); window.location.href=window.location.origin;}} className="btn btn-default btn-flat">Hindi</button>
                              {/* <a href="javascript:void(0);" onClick={()=>{sessionStorage.clear(); return <Redirect to="/" />}} className="btn btn-default btn-flat">Sign out</a> */}
                          
                      </li>
                  </ul>
              </li>
                <li className="dropdown user-menu">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span><i className="glyphicon glyphicon"></i> Welcome User <i className="caret"></i></span>
                  </a>
                  <ul className="dropdown-menu">
                       
                          <div className="bg-light-blue">
                      {/* <img src="#" alt="login" width="100%" className="" /> */}
                      </div>
                      
                      <li className="user-footer">

                          
                              <Link to="/user-profile" className="btn btn-default btn-flat">Logo</Link>
                              <Link to="/edit-profile" className="btn btn-default btn-flat">Profile</Link>
                              <Link to="/changepassword" className="btn btn-default btn-flat">Change Password</Link>
                              <Link to="/signout" className="btn btn-default btn-flat">Sign Out</Link>
                              {/* <a href="javascript:void(0);" onClick={()=>{sessionStorage.clear(); return <Redirect to="/" />}} className="btn btn-default btn-flat">Sign out</a> */}
                          
                      </li>
                  </ul>
              </li>
          </ul>
          </div>
          :
          null
}
      </nav>
  </header>
         {/* <aside className="left-side sidebar-offcanvas">
                
                <section className="sidebar">
                    
                    <div className="user-panel bg-light-blue">
                        
                        <img src={logo} alt="login" width="100%" className="" />
                    
                        
                    </div>
                    <Menu />
                </section>
            </aside> */}
            </React.Fragment>
    )
}

export default Header
