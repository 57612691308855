import React, {useState} from 'react'
import PrashnaPalane from './PrashnaPlanet'
function Prashan() {
    const [opt1, setOpt1]=useState("");
    const [opt2, setOpt2]=useState("");
    return (
<>
        <h1 className="heading">Prashna</h1>
        <div className="info preds">
            <div className="col-md-6">
                <h5>Option 1</h5>
                <label>{opt1}</label>
                <input type="range" className="form-control" min="1" max="249" value={opt1} onChange={(e:any)=>{setOpt1(e.target.value)}} placeholder="Enter Number for Option1 (1 to 249)" />
                {opt1!=="" && <PrashnaPalane key="1" number={opt1} />}
            </div>
            <div className="col-md-6">
            <h5>Option 2</h5>
                <label>{opt2}</label>
                <input type="range" className="form-control" min="1" max="249" value={opt2} onChange={(e:any)=>{setOpt2(e.target.value)}} placeholder="Enter Number for Option2 (1 to 249)" />
                {opt2!=="" && <PrashnaPalane key="2" number={opt2} />}
            </div>
        </div>
        </>
 )
}

export default Prashan
