const initialNative = { isNative:true,id:localStorage.getItem('id'), advice:localStorage.getItem('advice') || "", dob: localStorage.getItem('dob') || new Date(), fname: localStorage.getItem('fname') || "", mname: localStorage.getItem('mname') || "", lname: localStorage.getItem('lname') || "", gender: localStorage.getItem('gender') || ""}
// const initialNative = { dob: '', fname: '', mname: '', lname: ''}
console.log(initialNative);
const nativeReducer = (state = initialNative, action: any) => {
    switch (action.type) {
        case 'NATIVE':
            return { ...state, isNative: action.native };
        case 'ID':
            localStorage.setItem('id',action.id);
            return { ...state, id: action.id };
        case 'DOB':
            localStorage.setItem('dob',action.dob);
            return { ...state, dob: action.dob };
        case 'FNAME':
            localStorage.setItem('fname',action.fname);
            return { ...state, fname: action.fname };
        case 'MNAME':
            localStorage.setItem('mname',action.mname);
            return { ...state, mname: action.mname };
        case 'LNAME':
            localStorage.setItem('lname',action.lname);
            return { ...state, lname: action.lname };
        case 'GENDER':
            localStorage.setItem('gender',action.gender);
            return { ...state, gender: action.gender };
        case 'MOTHER':
            localStorage.setItem('mother',action.gender);
            return { ...state, gender: action.gender };
        case 'FATHER':
            localStorage.setItem('father',action.gender);
            return { ...state, gender: action.gender };
        case 'CONTACT':
            localStorage.setItem('contact',action.gender);
            return { ...state, gender: action.gender };
        case 'EMAIL':
            localStorage.setItem('email',action.gender);
            return { ...state, gender: action.gender };
        case 'ADDRESS':
            localStorage.setItem('address',action.gender);
            return { ...state, gender: action.gender };
        case 'ADVICE':
            localStorage.setItem('advice',action.advice);
            return { ...state, advice: action.advice };
        default:
            return state;
    }
}
export default nativeReducer;