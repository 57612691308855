import React, {useState, useRef} from 'react'
import ReactToPrint from 'react-to-print';
import PredData from './PredData';
export default function PrintPrediction() {
    const printRef=useRef();
    const [printPoints, setPrintPoints]=useState([]);
    const chk=[];
    const addPrint=(n)=>{
        setPrintPoints([...printPoints, n]);
        console.log("Print: Add", printPoints);
    }
    const removePrint=(n)=>{
        setPrintPoints(printPoints.filter(x=>x!==n));
        console.log("Print: Remove", printPoints);
    }
    return (
        <div>
            <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => printRef.current}
      />
      <p className='clearfix'></p>
      <div className='well col-md-4' style={{height:400}}>
               <h5 className='printheadcheck'><span style={{float:'left', marginLeft:10}}><input type="checkbox" onChange={(e)=>{e.target.checked ? setPrintPoints([...printPoints,1,2,3,4,21]) : setPrintPoints(printPoints.filter(x=>![1,2,3,4,21].includes(x)))}} /></span> Pythagorean</h5>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(1)} onChange={(e)=>{e.target.checked ? addPrint(1) : removePrint(1)}} />Personality</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(21)} onChange={(e)=>{e.target.checked ? addPrint(21) : removePrint(21)}} />Heart Desire / Expression / Personality Number</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(2)} onChange={(e)=>{e.target.checked ? addPrint(2) : removePrint(2)}} />Your Numbers</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(3)} onChange={(e)=>{e.target.checked ? addPrint(3) : removePrint(3)}} />Name Analysis</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(4)} onChange={(e)=>{e.target.checked ? addPrint(4) : removePrint(4)}} />Important years in your life</div>
        </div>
        <div className='well col-md-4' style={{height:400}}>
                <h5 className='printheadcheck'><span style={{float:'left', marginLeft:10}}><input type="checkbox" onChange={(e)=>{e.target.checked ? setPrintPoints([...printPoints,5,6,7,8]) : setPrintPoints(printPoints.filter(x=>![5,6,7,8].includes(x)))}} /></span> Chaldean</h5>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(5)} onChange={(e)=>{e.target.checked ? addPrint(5) : removePrint(5)}} />Grid</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(6)} onChange={(e)=>{e.target.checked ? addPrint(6) : removePrint(6)}} />Name Analysis</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(7)} onChange={(e)=>{e.target.checked ? addPrint(7) : removePrint(7)}} />Personal Year</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(8)} onChange={(e)=>{e.target.checked ? addPrint(8) : removePrint(8)}} />Your Number</div>
        </div>
        <div className='well col-md-4' style={{height:400}}>
                <h5 className='printheadcheck'><span style={{float:'left', marginLeft:10}}><input type="checkbox" onChange={(e)=>{e.target.checked ? setPrintPoints([...printPoints,9,10,11,12,13]) : setPrintPoints(printPoints.filter(x=>![9,10,11,12,13].includes(x)))}} /></span> Loshu</h5>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(9)} onChange={(e)=>{e.target.checked ? addPrint(9) : removePrint(9)}} />Grid</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(10)} onChange={(e)=>{e.target.checked ? addPrint(10) : removePrint(10)}} />Missing Number</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(11)} onChange={(e)=>{e.target.checked ? addPrint(11) : removePrint(11)}} />Small Arrow</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(12)} onChange={(e)=>{e.target.checked ? addPrint(12) : removePrint(12)}} />Vastu</div>
        <div className='checkprint'><input type="checkbox" checked={printPoints.includes(13)} onChange={(e)=>{e.target.checked ? addPrint(13) : removePrint(13)}} />Plane</div>
            </div>
            <div className='well col-md-4' style={{height:500}}>
            <h5 className='printheadcheckprime'><span style={{float:'left', marginLeft:10}}><input type="checkbox" onChange={(e)=>{e.target.checked ? setPrintPoints([...printPoints,14,15,16,17,18,19,20]) : setPrintPoints(printPoints.filter(x=>![14,15,16,17,18,19,20].includes(x)))}} /></span> Prime Membership</h5>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(14)} onChange={(e)=>{e.target.checked ? addPrint(14) : removePrint(14)}} />Dasha</div>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(15)} onChange={(e)=>{e.target.checked ? addPrint(15) : removePrint(15)}} />Health</div>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(16)} onChange={(e)=>{e.target.checked ? addPrint(16) : removePrint(16)}} />Mobile Number Analysis</div>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(17)} onChange={(e)=>{e.target.checked ? addPrint(17) : removePrint(17)}} />Career</div>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(18)} onChange={(e)=>{e.target.checked ? addPrint(18) : removePrint(18)}} />Marriage</div>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(19)} onChange={(e)=>{e.target.checked ? addPrint(19) : removePrint(19)}} />Compatibility</div>
            <div className='checkprint'><input type="checkbox" checked={printPoints.includes(20)} onChange={(e)=>{e.target.checked ? addPrint(20) : removePrint(20)}} />Numerologist Advise</div>
            </div>
            <p className='clearfix'></p>
            <PredData print={printPoints} ref={printRef} />
            <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => printRef.current}
      />
        </div>
    )
}
