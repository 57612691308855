import React, {useState} from 'react'
import { API_URL } from './../Global'
import { useDispatch, useSelector } from 'react-redux'
import {postProfileLogo} from './actions/profile'
import ImageUpload from './ImageUpload'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'

function UserProfile() {
    const getImage=()=>{
        let logo = API_URL+"user"+sessionStorage.getItem("logo").replace(".", "");
        console.log(logo);
        return logo;
    }
    const myState=useSelector((state)=>state.profileReducer);
    const dispatch=useDispatch();
    const [file, setFile]=useState(getImage());
    
    const uploadImage=()=>{
        // let formData=new FormData();
        // formData.append("myfile",file);
        let formData={image:file}
        console.log(formData);
        dispatch(postProfileLogo(formData));
    }
    const onFileChange=(e)=> {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
 
        fileReader.onload = (event) => {
            
                setFile(event.target.result)
        
        }
    }
    const onSubmit=()=>{
        const formData = { image: file, id:sessionStorage.getItem('uid') }
        let endpoint = API_URL+'user/imageupload.php';
         axios.post(endpoint, formData, {
         }).then((res) => {
            console.log(res); 
            sessionStorage.setItem("logo", res);
            alert('File uploaded!');
        })
    }
    return (
        <div style={{backgroundColor:'#fff', minHeight:800}}>
         <Header />
        <div className="info pred">
            <h3>Logo</h3>
           {file && <img src={file} width="200"/>}
           {/* <select onChange={}>
               <option value="1">Logo</option>
               <option value="2"></option>
           </select> */}
<div className="form-group mb-3">
                    <label className="text-white">Select File</label>
                    <input type="file" className="form-control" name="image" onChange={(e)=>onFileChange(e)} />
                </div>

                
                <div className="d-grid">
                   <button type="submit" className="btn btn-outline-primary" onClick={()=>onSubmit()}>Store</button>
                </div>
            {/* <ImageUpload setFile={setFile}/> */}
            {/* <button onClick={()=>uploadImage()}>Upload</button> */}
        </div>
        <Footer />
        </div>
    )
}

export default UserProfile
