import React, {useState} from 'react'
import * as Numerology from './calc/Numerology.js';
import {useSelector} from 'react-redux';
import PredPrint from './PredPrint';
import Popup from './Popup';
import LoshuGrid from './LoshuGrid';
import LoshuMissingh from './LoshuMissingh.jsx';
import SmallArrow from './SmallArrow.jsx';
import LoshuVastu from './LoshuVastu.jsx';
import LoshuPlane from './LoshuPlane.jsx';
function Loshu() {
    const [isSeen, setIsSeen]=useState(false);
    const [page, setPage]=useState('grid');
    const [bn, setBN]=useState(true);
    const [ln, setLN]=useState(true);
    const [kua, setKua]=useState(true);
    const [contents, setContents]=useState("");
    const Native=useSelector((state:any)=>state.nativeReducer);
    let grid:string;
    // grid=new Date(Number(Native.dob)).getDate().toString()+(new Date(Number(Native.dob)).getMonth()+1).toString()+new Date(Number(Native.dob)).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Number(Native.dob)).getDate().toString())+Numerology.getLifePathNumber(new Date(Number(Native.dob)).getDate().toString(),(new Date(Number(Native.dob)).getMonth()+1).toString(),new Date(Number(Native.dob)).getFullYear().toString())
    grid=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]+Numerology.kuaNumber(new Date(Native.dob).getFullYear(), Native.gender)
    // console.log(grid);
    grid=grid + (Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())[1] !== Number(new Date(Native.dob).getDate().toString())) ? Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())[1].toString() : "";
    
    
    
    const renderSwitch=()=>{
        switch(page){
            case 'grid':
                return <LoshuGrid />
            case 'missing':
                return <LoshuMissingh />
            case 'smallarrow':
                return  <SmallArrow />
            case 'vastu':
                return  <LoshuVastu />
            case 'plane':
                return  <LoshuPlane />
        } 
    }
    return (
        <div>
            <aside className="col-md-2 leftmenu">
                <h5 className="linkstyle" key="l1" onClick={()=>setPage('grid')}>Grid</h5>
                <h5 className="linkstyle" key="l2" onClick={()=>setPage('missing')}>Missing Numbers</h5>
                <h5 className="linkstyle" key="l3" onClick={()=>setPage('smallarrow')}>Small Arrow</h5>
                <h5 className="linkstyle" key="l4" onClick={()=>setPage('vastu')}>Loshu Vastu</h5>
                <h5 className="linkstyle" key="l5" onClick={()=>setPage('plane')}>Loshu Plane</h5>
            </aside>
            <aside className="col-md-10 contents">
            {renderSwitch()}
            </aside>
        </div>
    )
}

export default Loshu
