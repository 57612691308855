import React, {useState} from 'react'
import * as Numerology from './calc/Numerology'
import {useSelector} from 'react-redux';
function PythagoreanName() {
    const Native=useSelector((state:any)=>state.nativeReducer);
    const [fname, setFname]=useState(Native.fname);
    const [name1, setName1]=useState(Native.fname+" "+Native.mname+" "+Native.lname);
    const [name2, setName2]=useState("");
    const [name3, setName3]=useState("");
    const [name4, setName4]=useState("");
    const [name5, setName5]=useState("");
    const [mname, setMname]=useState(Native.mname);
    const [lname, setLname]=useState(Native.lname);
    Numerology.getAntiSuitable();
    return (
        <div>
            <h3 className="heading">Pythagorean Name Analysis</h3>
            <div className="info clearfix">
                <p className="preds">Our name plays a very important role in our life. A proper name can help you to scale new heights and an opposite name can remove all the luxuries of your life. 
                    Along your birth numbers, your name is the most important factor in deciding your personal growth. 
                    A corrected name can correct the defects of the date of birth and turn things around for the individual or the business.</p>
            <div className="col-md-4 preds screenonly">
            <table className="table table-bordered table-striped">
                <thead><tr><th>Number</th><th>Letters</th></tr></thead>
                <tbody>
            {Numerology.PythagoreanLetters.map((x,i)=><tr><td>{i+1}</td><td>{x}</td></tr>)}
            </tbody>
            </table>
            </div>
            <div className="col-md-8 preds pagebreak">
                <div className="well">
                <div className="col-md-4">
                    <label>First Name</label>
                    <input type="text" autoComplete="none" value={fname} onChange={(e)=>setFname(e.target.value)} className="form-control" />
                    {fname!="" && Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.pythagoreanCalc(fname)[1]))]}
                </div>
                <div className="col-md-4">
                    <label>Middle Name</label>
                    <input type="text" autoComplete="none" value={mname} onChange={(e)=>setMname(e.target.value)} className="form-control" />
                    {/* {mname!="" && Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.pythagoreanCalc(mname)[1])[1])]} */}
                </div>
                <div className="col-md-4">
                    <label>Last Name</label>
                    <input type="text" autoComplete="none" value={lname} onChange={(e)=>setLname(e.target.value)} className="form-control" />
                    {/* {lname!="" && Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.pythagoreanCalc(lname)[1])[1])]} */}
                </div>
                <p className="clearfix"></p>
                </div>
                {/* <div className="well"> */}
                
                {/* </div> */}
                {/* <table className="table table-bordered">
                    <tbody>
                        <tr>
                            {fname.split('').map((x:any)=><td>{x}</td>)}
                            {mname.split('').map((x:any)=><td>{x}</td>)}
                            {lname.split('').map((x:any)=><td>{x}</td>)}
                        </tr>
                        <tr>
                        {fname.split('').map((x:any)=><td>{Numerology.pythagorean(x)}</td>)}
                        {mname.split('').map((x:any)=><td>{Numerology.pythagorean(x)}</td>)}
                        {lname.split('').map((x:any)=><td>{Numerology.pythagorean(x)}</td>)}
                        </tr>
                    </tbody>
                </table> */}
                <h4>Expression Number: {Numerology.expressionNumber(fname,mname,lname)[1]}</h4>
                <h4>Heart Desire Number: {Numerology.heartDesireNumber(fname,mname,lname)[1]}</h4>
                <h4>Personality Number: {Numerology.personalityNumber(fname,mname,lname)[1]}</h4>
                First Name: {fname} {fname!=="" && Numerology.pythagoreanCalc(fname)[1]} ({fname!=="" && Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname))} {fname!=="" && Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname))!=="Fovourable" && " || You should consult a good numerologist. || "})<br />
                Full Name: {`${fname} ${mname} ${lname}`} {fname!=="" && Numerology.pythagoreanCalc(fname+mname+lname)[1]} ({fname!=="" && Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname+mname+lname))} {fname!=="" && Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname+mname+lname))!=="Fovourable" && " || You should consult a good numerologist. ||"})
                {/* {fname!=="" && Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname)[1])} */}
                {/* {fname!="" && <span style={{fontSize:16, textAlign:'left' }}>First Name: ({Numerology.singleDigit(Numerology.pythagoreanCalc(fname)[1])[1]}) {Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname)[1])} | {Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname)[1])==="Avoidable" && <span className="text-red">You should consult a good Numerologist.</span>}</span>} */}
                {/* {mname!="" && <span style={{fontSize:16, textAlign:'left' }}> | Middle Name: {Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.pythagoreanCalc(mname)[1])[1])]}</span>}
                {lname!="" && <span style={{fontSize:16, textAlign:'left' }}> | Last Name: {Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.pythagoreanCalc(lname)[1])[1])]}<br /></span>} */}
                {/* {fname+mname+lname!=="" && <span><br />Full Name: ({Numerology.singleDigit(fname)[1]}) | </span>} */}
                {/* {fname+mname+lname!=="" && <span><br />Full Name: ({Numerology.singleDigit(fname+mname+lname)[1]}) {Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname+mname+lname)[1])} | {Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname+mname+lname)[1])==="Avoidable" && <span className="text-red">You should consult a good Numerologist.</span>}</span>} */}
                <p className="clearfix"></p><br />
                {/* {Numerology.getAntiSuitable(Numerology.pythagoreanCalc(fname+mname+lname)[1])==="Avoidable" && <span className="alert alert-danger">You should consult a good Numerologist.</span>} */}
                <p className="clearfix"></p>
            </div>
            <p className="clearfix"></p>
            </div>
        </div>
    )
}

export default PythagoreanName
