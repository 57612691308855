import React ,{useState, useRef}from 'react'
import Header from './Header';
import Footer from './Footer';
import DateDD from './DateDD';
import DateMM from './DateMM';
import DateYYYY from './DateYYYY';
import ReactToPrint from 'react-to-print';

import * as Numerology from './calc/Numerology';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
function MatchMaking() {
    const printRef=useRef();
    const [check, setCheck]=useState(false);
    const [date, setDate]=useState("01");
    const [month, setMonth]=useState(0);
    const [year, setYear]=useState(new Date().getFullYear().toString());
    const [date2, setDate2]=useState("01");
    const [month2, setMonth2]=useState(0);
    const [year2, setYear2]=useState(new Date().getFullYear().toString());
    
    const [name1, setName1]=useState('');
    const [name2, setName2]=useState('');
    const [gender1, setGender1]=useState('');
    const [gender2, setGender2]=useState('');
    const [dob1, setDob1]=useState(new Date(year+'-'+(Number(month)+1)+'-'+date).toString());
    const [dob2, setDob2]=useState(new Date(year2+'-'+(Number(month2)+1)+'-'+date2).toString());
    const [isSeen, setIsSeen]=useState(false);
    const [contents, setContents]=useState("");
    const getGrid=(dob1)=>new Date(dob1).getDate().toString()+(new Date(dob1).getMonth()+1).toString()+new Date(dob1).getFullYear().toString().split('')[2]+new Date(dob1).getFullYear().toString().split('')[3]+Numerology.getLifePathNumber(new Date(dob1).getDate().toString()+(new Date(dob1).getMonth()+1).toString(),(new Date(dob1).getFullYear().toString()))[1]+Numerology.getBirthdayNumber(new Date(dob1).getDate().toString())[1];
    const getTimes=(y, grid)=>{
        let p='';
        // console.log(grid);
        grid.split('').map(x=>{return Number(x)===y ? p=p+x : null;});
        return p;
    }     
    const mm=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];  
    // console.log("LifepathNo",new Date(dob1).getDate().toString(),"=>",Numerology.getLifePathNumber(new Date(dob1).getDate().toString()+(new Date(dob1).getMonth()+1).toString(),(new Date(dob1).getFullYear().toString()))); 
    // console.log("LifepathNo",new Date(dob1).getDate().toString(),"=>",Numerology.getBirthdayNumber(new Date(dob1).getDate().toString())); 
    // console.log("LifepathNo",new Date(dob1).getDate().toString(),"=>",getGrid(dob1)); 
    return (
        <div style={{backgroundColor:'#fff'}} className='onlyscreen'>
            <Header />
            <div className="info preds">
            {check && <div style={{float:'right'}}><ReactToPrint
        trigger={() => <button>Print</button>}
        content={() => printRef.current}
      /></div>}
            <div className="col-md-4">
            
                <div className="well">
                    <label>Birth Date</label>
                    <p className="clearfix"></p>
                    {/* <DateTimePickerComponent 
                    placeholder="Select Birth Date"
                    format="dd-MM-yyyy HH:mm"
                    step={1}
                    value={dob1}
                    onChange={(e)=>{setDob1(e.target.value); }}
                    ></DateTimePickerComponent> */}
                    <div className="col-md-4">
                    <DateDD value={date} updateDate={(v)=>{setDate(v); setDob1(new Date(year+'-'+(Number(month)+1)+'-'+date).toString()); }} />
                    </div>
                    <div className="col-md-4">
                <DateMM value={month} updateMonth={(v)=>{setMonth(v); setDob1(new Date(year+'-'+(Number(month)+1)+'-'+date).toString()); }} />
                </div>
                    <div className="col-md-4">
                <DateYYYY value={year} updateYear={(v)=>{setYear(v); setDob1(new Date(year+'-'+(Number(month)+1)+'-'+date).toString()); }} />
                </div>
                    <p className="clearfix"></p>
                    <label>Gender</label><br />
                    <input type="radio" name="g1" onChange={(e)=>setGender1('Male')} checked={gender1==='Male' ? true : false} /> Male 
                    <input type="radio" name="g1" onChange={(e)=>setGender1('Female')} checked={gender1==='Female' ? true : false}  /> Female 
                    <input type="radio" name="g1" onChange={(e)=>setGender1('Other')} checked={gender1==='Other' ? true : false}  /> Other
                    <br />
                    <div className="clearfix"></div>
                    <div>
                    <label>Name</label>
                    <input type="text" className="form-control" value={name1} onChange={(e)=>setName1(e.target.value)} />
                    </div>
                    <br />
                    <div className="clearfix"></div>
                    </div>
                    <div className="well">
                    <label>Birth Date</label>
                    <p className="clearfix"></p>
                    {/* <DateTimePickerComponent 
                    placeholder="Select Birth Date"
                    format="dd-MM-yyyy HH:mm"
                    step={1}
                    value={dob2}
                    onChange={(e)=>{setDob2(e.target.value); }}
                    ></DateTimePickerComponent> */}
                    <div className="col-md-4">
                    <DateDD value={date2} updateDate={(v)=>{setDate2(v); setDob2(new Date(year2+'-'+(Number(month2)+1)+'-'+date2).toString()); }} />
                    </div>
                    <div className="col-md-4">
                <DateMM value={month2} updateMonth={(v)=>{setMonth2(v); setDob2(new Date(year2+'-'+(Number(month2)+1)+'-'+date2).toString()); }} />
                </div>
                    <div className="col-md-4">
                <DateYYYY value={year2} updateYear={(v)=>{setYear2(v); setDob2(new Date(year2+'-'+(Number(month2)+1)+'-'+date2).toString()); }} />
                </div>
                    <p className="clearfix"></p>
                    
                    <label>Gender</label><br />
                    <input type="radio" name="g2" onChange={(e)=>setGender2('Male')} checked={gender2==='Male' ? true : false} /> Male 
                    <input type="radio" name="g2" onChange={(e)=>setGender2('Female')} checked={gender2==='Female' ? true : false}  /> Female 
                    <input type="radio" name="g2" onChange={(e)=>setGender2('Other')} checked={gender2==='Other' ? true : false}  /> Other
                    
                    <br />
                    <div className="clearfix"></div>
                    <div>
                    <label>Name</label>
                    <input type="text" className="form-control" value={name2} onChange={(e)=>setName2(e.target.value)} />
                    </div>
                    <br />
            <div className="clearfix"></div>
                    </div>
            <button className="btn btn-primary" onClick={()=>{setDob1(new Date(year+'-'+(Number(month)+1)+'-'+date).toString()); setDob2(new Date(year2+'-'+(Number(month2)+1)+'-'+date2).toString()); dob1 && dob2 && setCheck(true)}}>Check</button>
            </div>
            <div className="col-md-8" ref={printRef}>
                <h3>Compatibility Findings</h3><hr />
                {
                    check && 
                <>
                <div className='well'>
                    <p className='alert alert-primary'>
                        Person 1: {name1} {gender1} Date of Birth: {`${date}-${mm[Number(month)]}-${year}`}
                    </p>
                    <p className='alert alert-primary'>
                        Person 2: {name2} {gender2} Date of Birth: {`${date2}-${mm[Number(month2)]}-${year2}`}
                    </p>
                </div>
                <div className="well">
                {dob1 && dob2 && <span><div className="text-purple"> As per Birthdate Number compatibility is as follows:</div><br />{Numerology.getCompatibility(Numerology.getBirthdayNumber(new Date(dob1).getDate())[1],Numerology.getBirthdayNumber(new Date(dob2).getDate())[1])}</span>}
                <p>{Numerology.getCompatibilityPreds(Numerology.getCompatibility(Numerology.getBirthdayNumber(new Date(dob1).getDate())[1],Numerology.getBirthdayNumber(new Date(dob2).getDate())[1]))}</p>
                </div>
                <div className="well">
                
                {dob1 && dob2 && <span><div className="text-purple">As per Lifepath Number compatibility is as follows:</div><br />{Numerology.getCompatibility(Numerology.getLifePathNumber(new Date(dob1).getDate(),new Date(dob1).getMonth()+1,new Date(dob1).getFullYear())[1],Numerology.getLifePathNumber(new Date(dob2).getDate(),new Date(dob2).getMonth()+1,new Date(dob2).getFullYear())[1])}</span>}
                <p>{Numerology.getCompatibilityPreds(Numerology.getCompatibility(Numerology.getLifePathNumber(new Date(dob1).getDate(),new Date(dob1).getMonth()+1,new Date(dob1).getFullYear())[1],Numerology.getLifePathNumber(new Date(dob2).getDate(),new Date(dob2).getMonth()+1,new Date(dob2).getFullYear())[1]))}</p>
                </div>
                <div className="well">
                    
                    {dob1 && dob2 && <span><div className="text-purple">As per Birthdate and Birth Month.</div><br /> {Numerology.birthDateMonth(Numerology.getBirthdayNumber(new Date(dob1).getDate())[1], new Date(dob2).getMonth())}</span>}

                </div>
                <div className="well">
                    <div className="col-md-6" style={{border:'2px #aaa dashed', borderRadius:20, padding:10}}>
                        <h5>Partner 1</h5>
                        
                        {/* {grid1=new Date(dob1).getDate().toString()+(new Date(dob1).getMonth()+1).toString()+new Date(dob1).getFullYear().toString().split('')[2]+new Date(dob1).getFullYear().toString().split('')[3]+Numerology.getLifePathNumber(new Date(dob1).getDate().toString(),(new Date(dob1).getMonth()+1).toString(),(new Date(dob1).getFullYear().toString()))[1]} */}
                        {dob1 && Numerology.chaldeanGrid.map((x)=>x.map((y)=><div className='col-xs-4' style={{border:'1px #999 solid', height:50, textAlign:'center', padding:15, cursor:'pointer'}}>{getGrid(dob1).split('').includes(y.toString()) ? <span onClick={()=>{setContents(Numerology.getPreds(getTimes(y, getGrid(dob1)), false));setIsSeen(true)}}>{getTimes(y, getGrid(dob1))}</span> : <span style={{color:'#ccc'}} onClick={()=>{setContents(Numerology.getPreds(y, true));setIsSeen(true)}}>{y}</span>}</div>))}
                    Number gives: {getGrid(dob1).split('').filter((item, pos, self)=>self.indexOf(item)===pos)}
                    </div>
                    <div className="col-md-6" style={{border:'2px #aaa dashed', borderRadius:20, padding:10}}>
                        <h5>Partner 2</h5>
                        
                    {/* {grid2=new Date(dob2).getDate().toString()+(new Date(dob2).getMonth()+1).toString()+new Date(dob2).getFullYear().toString().split('')[2]+new Date(dob2).getFullYear().toString().split('')[3]+Numerology.getLifePathNumber(new Date(dob2).getDate().toString(),(new Date(dob2).getMonth()+1).toString(),(new Date(dob2).getFullYear().toString()))[1]} */}
                        {dob2 && Numerology.chaldeanGrid.map((x)=>x.map((y)=><div className='col-xs-4' style={{border:'1px #999 solid', height:50, textAlign:'center', padding:15, cursor:'pointer'}}>{getGrid(dob2).split('').includes(y.toString()) ? <span onClick={()=>{setContents(Numerology.getPreds(getTimes(y, getGrid(dob2)), false));setIsSeen(true)}}>{getTimes(y, getGrid(dob2))}</span> : <span style={{color:'#ccc'}} onClick={()=>{setContents(Numerology.getPreds(y, true));setIsSeen(true)}}>{y}</span>}</div>))}
                        Number gives: {getGrid(dob2).split('').filter((item, pos, self)=>self.indexOf(item)===pos)}
                    </div>
                    <div className="clearfix"></div>
                    <br />
                    <div className="alert alert-info">{getGrid(dob1).split('').filter((item, pos, self)=>self.indexOf(item)===pos).length>getGrid(dob2).split('').filter((item, pos, self)=>self.indexOf(item)===pos).length ? "Partner 1 will lead." : getGrid(dob1).split('').filter((item, pos, self)=>self.indexOf(item)===pos).length<getGrid(dob2).split('').filter((item, pos, self)=>self.indexOf(item)===pos).length ? "Partner 2 will lead" : "Both Partner will have equal participation."}</div>
                    <div className="clearfix"></div>
                </div>
            <br />
            </>
}
            </div>
        
            <div className="clearfix"></div>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <Footer />
        </div>
    )
}

export default MatchMaking
