import { ClientRequest } from "http";

const initialState={
    test:0,
    clients:[],
    dob:'',
    fname:'',
    mname:'',
    lname:''
};

const profileReducer=(state:any=initialState, action:any)=>{
        switch(action.type){
            case 'GET_PROFILE':
                return {...state, clients:action.clientList};
            case 'POST_PROFILE':
                let newState=state;
                newState.clients.push(action.client);
                newState.dob=action.client.dob;
                newState.fname=action.client.fname;
                newState.mname=action.client.mname;
                newState.lname=action.client.lname;
                return newState;
            case 'CHANGE_PROFILE':
                return {...state, dob: action.profile.dob, fname:action.profile.fname, mname:action.profile.mname, lname:action.profile.lname}
            default:
                console.log('Default');
                return state;
        }
}
export default profileReducer;