import React from 'react'

function Footer() {
    return (
        <div>
             <aside className="right-side">
        <section style={{position:'fixed', bottom:0, right:0, backgroundColor:'#A9A9A9', minWidth:'100vw'}}>
        <h4 style={{marginRight:10, float:'right', color:'#FF383F'}}>All Rights Reserved to &copy; Authentic Astro
        </h4>
    </section>
        </aside>
        </div>
    )
}

export default Footer
