import React from 'react'

function Compatibility() {
    return (
        <div>
            Compatibility.
        </div>
    )
}

export default Compatibility
