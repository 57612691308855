import React from 'react'

import * as Numerology from './calc/Numerology';
function PrashnaPlanet(props:any) {
    return (
        <div>
            <div className="well">
                <div className="col-md-4">
                    <h4>Lord</h4>
                    <h5 className="alert alert-info">{Numerology.getPrashnaNumber(props.number)[0]}</h5>
                </div>
                <div className="col-md-4">
                    <h4>Nakshatra Lord</h4>
                    <h5 className="alert alert-info">{Numerology.getPrashnaNumber(props.number)[1]}</h5>
                </div>
                <div className="col-md-4">
                    <h4>Sub Lord</h4>
                    <h5 className="alert alert-info">{Numerology.getPrashnaNumber(props.number)[2]}</h5>
                </div>
                <p className="clearfix"></p>
            </div>
        </div>
    )
}

export default PrashnaPlanet
