import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Chaldean from './Chaldean';
import Default from './Default';
import Footer from './Footer';
import Header from './Header';
import * as Numerology from './calc/Numerology';
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Prediction from './Prediction';
import * as Action from './actions/Native';
import {useSelector, useDispatch} from 'react-redux';
function Dashboard() {
  // const Native=useSelector((state:any)=>state.nativeReducer);
  // const [isNative, setNative] = useState(true);
  const native=useSelector((state:any)=>state.nativeReducer);
  const [nativeColor, setNativeColor] = useState('#fff');
  // const [nativeColor, setNativeColor] = useState('#989898');
  // const changeNative = () => {
  //   setNative(!isNative);
  // }
  const dispatch=useDispatch();
  return (
    <div>
      <Header />
      <Router>
      <aside>
        <section className="content" style={{ minHeight: 700, paddingBottom: 200 }}>
          {/* <Link to="/native"><span className="material-icons" onMouseOver={() => setNativeColor('darkmagenta')} onMouseLeave={() => setNativeColor('#2E8BC0')} style={{ color: nativeColor, cursor: 'pointer' }}>account_circle</span></Link> | {<span>{`Name: ${native.fname} ${native.mname} ${native.lname}, Dob: ${native.dob}`} | Birthdate Number: {Numerology.getBirthdayNumber(new Date(native.dob).getDate())[1]}, LifePath Number: {Numerology.getLifePathNumber(new Date(native.dob).getDate(),(new Date(native.dob).getMonth()+1),new Date(native.dob).getFullYear())[1]}</span>} */}
          <div className="well" style={{height:25, paddingTop:0}}><span className="" onMouseOver={() => setNativeColor('#FF383F')} onMouseLeave={() => setNativeColor('#fff')} onClick={()=>{localStorage.length>0 ? dispatch(Action.Native(!native.isNative)) : alert("No data selected")}} style={{ color: nativeColor, cursor: 'pointer', backgroundColor:'#123', borderRadius:5, paddingRight:10, paddingLeft:10 }}>{!native.isNative ? <span>New</span> : <span>Prediction</span>}</span> { !native.isNative && <span>| {`Name: ${native.fname} ${native.mname} ${native.lname}, Dob: ${native.dob}`} | Birthdate Number: {Numerology.getBirthdayNumber(new Date(native.dob).getDate())[1]}, LifePath Number: {Numerology.getLifePathNumber(new Date(native.dob).getDate(),(new Date(native.dob).getMonth()+1),new Date(native.dob).getFullYear())[1]}</span>}</div>
{/*           
            <Switch>
              <Route exact path="/"> */}
              {native.isNative ? <Default />: <Prediction />}
              {/* </Route>
              <Route path="/native"> */}
                
              {/* </Route>
            </Switch> */}
          
        </section>
      </aside>
      </Router>
      <Footer />
    </div>
  )
}

export default Dashboard
