import React, {useState} from 'react'
// import Tooltip from 'react-tooltip-lite';
import { setSourceMapRange } from 'typescript';
import * as Numerology from './calc/Numerology';
import PredPrint from './PredPrint';
import Popup from './Popup';
import {useSelector} from 'react-redux';
    
function ChaldeanGrid() {
    const Native=useSelector((state:any)=>state.nativeReducer);
let grid:string;
    // grid=new Date(Number(Native.dob)).getDate().toString()+(new Date(Number(Native.dob)).getMonth()+1).toString()+new Date(Number(Native.dob)).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Number(Native.dob)).getDate().toString())+Numerology.getLifePathNumber(new Date(Number(Native.dob)).getDate().toString(),(new Date(Number(Native.dob)).getMonth()+1).toString(),new Date(Number(Native.dob)).getFullYear().toString())
    // grid=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())
    grid=new Date(localStorage.dob).getDate().toString()+(new Date(localStorage.dob).getMonth()+1).toString()+new Date(localStorage.dob).getFullYear().toString().split('')[2]+new Date(localStorage.dob).getFullYear().toString().split('')[3]+Numerology.getLifePathNumber(new Date(localStorage.dob).getDate().toString(),(new Date(localStorage.dob).getMonth()+1).toString(),(new Date(localStorage.dob).getFullYear().toString()))[1]
    grid+=(Number(new Date(localStorage.dob).getDate())>10 && Number(new Date(localStorage.dob).getDate())!==20 && Number(new Date(localStorage.dob).getDate())!==30) ? Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate().toString())[1].toString() : "";
    // console.log(localStorage.dob);
    // console.log(Number(new Date(localStorage.dob).getDate().toString()));
    // Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())[1] !== Number(new Date(Native.dob).getDate().toString()) && console.log(Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())[1])
    // console.log(grid);
    const [isSeen, setIsSeen]=useState(false);
    const [contents, setContents]=useState("");
    const getTimes=(y:number)=>{
        let p:string='';
        grid.split('').map(x=>Number(x)===y ? p=p+x : null);
        return p;
    }
    const getOccurance=()=>{
        let b=[];
        let a=Numerology.getAllNumbers(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1, (new Date(localStorage.dob).getFullYear().toString().split('')[2]+new Date(localStorage.dob).getFullYear().toString().split('')[3]),Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1],Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1]);
        // a.map(x=>{console.log(Numerology.getPythagoreanCharacteristics(x))});
    }
    return (
        <div>
            <h3 className="heading">Chaldean Grid Analysis</h3>
            <div className="info preds">
            <div className="col-xs-12 col-sm-12 col-md-3">
                {localStorage.dob && Numerology.chaldeanGrid.map((x:number[])=>x.map((y)=><div className='col-xs-4' style={{border:'1px #999 solid', height:50, textAlign:'center', padding:15, cursor:'pointer'}}>{grid.split('').includes(y.toString()) ? <span onClick={()=>{setContents(Numerology.getPreds(getTimes(y), false));setIsSeen(true)}}>{getTimes(y)}</span> : <span style={{color:'#ccc'}} onClick={()=>{setContents(Numerology.getPreds(y, true));setIsSeen(true)}}>{y}</span>}</div>))}
            </div>
            {Numerology.getPythagoreanCombo(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1])}
            {/* {Numerology.getAllNumbers(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1, (new Date(localStorage.dob).getFullYear().toString().split('')[2]+new Date(localStorage.dob).getFullYear().toString().split('')[3]),Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1],Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1]).map(x=><PredPrint number={x} prediction={Numerology.getPythagoreanCharacteristics(x)} />)} */}
            <br />
            <br />

            {Numerology.chaldeanPreds(grid).map((x:any)=><p style={{textAlign:'justify', border:'0px #ccc dashed'}}><strong>{x} :</strong> {Numerology.getChaldeanPreds(x)}</p>)}
            </div>
            {isSeen ? <Popup close={()=>setIsSeen(false)} contents={contents} /> : null}
        </div>
    )
}

export default ChaldeanGrid
