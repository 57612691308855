import React from 'react';
import * as Numerology from './calc/Numerology';

function PythagoreanCareer() {
    let bn=Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1];
    let ln=Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1];
    

    return (
        <div>
            <h3 className='heading'>Career Chart:</h3>
            
            <div className="info preds">
            <p>Career and finance are two most important aspects in anyone’s life. 
                A stable career can give you a stable financial status and a secured financial status can empowers us to choose our career wisely so that we can achieve the maximum in life- this way the both are inter dependent. 
                A detail study of your birth numbers can provide a clear indication as how your career and finance will be. </p>
                {Numerology.getPrediction(bn.toString())}
                <br />
                <br />
                {bn!==ln && Numerology.getPrediction(ln.toString())}
            </div>
        </div>
    )
}

export default PythagoreanCareer
