import React, {useState} from 'react'
import * as Numerology from './calc/Numerology'
import {useSelector} from 'react-redux';
function ChaldeanNameAnalysis() {
    const Native=useSelector((state:any)=>state.nativeReducer);
    const [name1, setName1]=useState(Native.fname+" "+Native.mname+" "+Native.lname);
    const [name2, setName2]=useState("");
    const [name3, setName3]=useState("");
    const [name4, setName4]=useState("");
    const [name5, setName5]=useState("");
    const [fname, setFname]=useState(Native.fname);
    const [mname, setMname]=useState(Native.mname);
    const [lname, setLname]=useState(Native.lname);
    return (
        <div>
            <h3 className="heading">Chaldean Name Analysis</h3>
            <div className="info clearfix">
                <p className="preds">Our name plays a very important role in our life. A proper name can help you to scale new heights and an opposite name can remove all the luxuries of your life. 
                    Along your birth numbers, your name is the most important factor in deciding your personal growth. 
                    A corrected name can correct the defects of the date of birth and turn things around for the individual or the business.</p>
            <div className="col-md-4 preds onlyscreen">
            <table className="table table-bordered table-striped">
                <thead><tr><th>Number</th><th>Letters</th></tr></thead>
                <tbody>
            {Numerology.ChaldeanLetters.map((x,i)=><tr><td>{i+1}</td><td>{x}</td></tr>)}
            </tbody>
            </table>
            </div>
            <div className="col-md-8 preds">
                <div className="well">
                <div className="col-md-4">
                    <label>First Name</label>
                    <input type="text" autoComplete="none" value={fname} onChange={(e)=>setFname(e.target.value)} className="form-control" />
                    {fname!="" && Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.chaldeanCalc(fname)[1])[1])]}
                </div>
                <div className="col-md-4">
                    <label>Middle Name</label>
                    <input type="text" autoComplete="none" value={mname} onChange={(e)=>setMname(e.target.value)} className="form-control" />
                    {mname!="" && Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.chaldeanCalc(mname)[1])[1])]}
                </div>
                <div className="col-md-4">
                    <label>Last Name</label>
                    <input type="text" autoComplete="none" value={lname} onChange={(e)=>setLname(e.target.value)} className="form-control" />
                    {lname!="" && Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.chaldeanCalc(lname)[1])[1])]}
                </div>
                <p className="clearfix"></p>
                </div>
                
                
                {fname!="" && <span>First Name: {`${fname}`} {fname!=="" && Numerology.chaldeanCalc(fname)[1]} {fname!=="" && Numerology.getAntiSuitable(Numerology.chaldeanCalc(fname))} {fname!=="" && Numerology.getAntiSuitable(Numerology.chaldeanCalc(fname))!=="Fovourable" && " || You should consult a good numerologist. ||"}</span>}<br />
                {/* {mname!="" && <span style={{fontSize:16, textAlign:'left' }}> | Middle Name: {Numerology.PythaSuitable[Numerology.getChaldeanSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.chaldeanCalc(mname)[1])[1])]}</span>} */}
                {/* {lname!="" && <span style={{fontSize:16, textAlign:'left' }}> | Last Name: {Numerology.PythaSuitable[Numerology.getChaldeanSuitable(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1], Numerology.singleDigit(Numerology.chaldeanCalc(lname)[1])[1])]}<br /></span>} */}
                {fname+mname+lname!=="" && <span>Full Name: {`${fname} ${mname} ${lname}`} {fname+mname+lname!=="" && Numerology.chaldeanCalc(fname+mname+lname)[1]} {fname!=="" && Numerology.getAntiSuitable(Numerology.chaldeanCalc(fname+mname+lname))} {fname!=="" && Numerology.getAntiSuitable(Numerology.chaldeanCalc(fname+mname+lname))!=="Fovourable" && " || You should consult a good numerologist. ||"}</span>}
                <p className="clearfix"></p><br />
                
            </div>
            <p className="clearfix"> </p>
            </div>
        </div>
    )
}

export default ChaldeanNameAnalysis
