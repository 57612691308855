import React from 'react'
function DateYYYY(props) {
    const y=new Date().getFullYear();
    const years=Array.from(new Array(100), (val, index)=>y-index);
    const yyyy=years.map((x,i)=><option value={x} key={i} selected={props.year==x && 'selected'}>{x}</option>)    
    return (
        <div>
            <select className="datedd" onChange={(e)=>props.updateYear(e.target.value)}>
                {yyyy}
            </select>
        </div>
    )
}

export default DateYYYY
