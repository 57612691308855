import React, {useState, useEffect} from 'react'
import Header from './Header'
import { API_URL } from '../Global'
import axios from 'axios'
function Admin() {
    const [userList, setUserList]=useState([])
    const [password, setPassword]=useState('')
    useEffect(()=>{
        console.log(API_URL+'user/listuser.php');
        axios.get(API_URL+'user/listuser.php')
        .then(response=>{console.log(response);return response.data})
        .then((data:any)=>{console.log(data);setUserList(data)})
    },[])
 const activateUser=(uid:any)=>{
  fetch(API_URL+'user/activateuser.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify({"uid":uid, "password":password})
})
.then((response)=>{console.log(response.status);return response.status})
.then((data)=>{alert("Process Complete")})
.catch(error=>alert(error));
 }
  return (
    <>
        <div style={{backgroundColor:'#fff', height:'100vh'}}>
            <Header />

            <div className="login-box-body">

            <h3>User List (<span className='small'>Password: <input type="password" onChange={(e)=>setPassword(e.target.value)} value={password} /></span>)  
            </h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Expiry Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {
                     userList.map((x:any)=><tr><td>{x.name}</td><td>{x.email}</td><td>{x.contact}</td><td>{x.expiry}</td><td><button className="btn btn-success" onClick={()=>activateUser(x.id)}>Activate</button></td></tr>)
                }
                </table>
                    </div>
                    </div>
    </>
  )
}

export default Admin