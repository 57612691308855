import axios from 'axios';
import Store from '../store/Store';
import { API_URL } from '../../Global';
import { Redirect } from 'react-router';
const login=(user)=>{
    console.log(user);
    return {type:'LOGIN', userdata:user};
}
export const checkLogin=(email, password)=>()=>
{
    sessionStorage.clear();
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('password', password);
    let params = new URLSearchParams();
              params.append('email', email);
            params.append('password', password);
    axios.post(API_URL+'user/login.php', params)
    .then(response=>response.data)
    .then(data=>{console.log(data);if(data.status==="Expired"){alert(data.msg)}else if(data[0].uid!==null) { sessionStorage.setItem("uid", data[0].uid); sessionStorage.setItem("logo",data[0].logo)} return Store.dispatch(login(data));})
    .catch(error=>{console.log(error);alert("Something went wrong. Please try again.")});
}
// export const checkLogin=(creds:any)=>{
// export const checkLogin= (email:string, password:string)=>async()=>{
//      fetch(API_URL+'user/login.php', 
// {
//     method:'POST',
//     mode: 'no-cors', 
//     cache: 'no-cache', 
//     credentials: 'omit',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     referrerPolicy: 'no-referrer', 
//     body:JSON.stringify({email,password})
// })
// .then((response:any)=>{console.log(response); return response.data})
// .then((data:any)=>{console.log(data);Store.dispatch(login(data))})
// .catch(error=>console.log(error));
// }
