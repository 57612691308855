import React, {useState, useEffect} from 'react'
import { API_URL } from './Global';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
function EditProfile() {
    const [name, setName]=useState('');
    const [address, setAddress]=useState('');
    const [contact, setContact]=useState('');
    const [email, setEmail]=useState('');
    const [uurl, setUurl]=useState('');
    const [password, setPassword]=useState('');
    useEffect(()=>{
        fetchUser();
    },[]);
    const fetchUser=()=>{
        axios.get(API_URL+'user/readUser.php?uid='+sessionStorage.getItem('uid'))
.then(response => response.data)
.then(data=>{
    console.log(data);
    setName(data[0].name);
    setAddress(data[0].address);
    setContact(data[0].contact);
    setEmail(data[0].email);
    setUurl(data[0].url);
})
.catch(error=>console.log(error));
    }
    const handleSubmit=()=>{
        if(password!==""){
        fetch(API_URL+'user/editprofile.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify({"password":password,"name":name,"address":address,"contact":contact,"email":email,"url":uurl,"uid":sessionStorage.getItem('uid')})
})
.then((response)=>{console.log(response.status);return response.status})
.then((data)=>{alert("Process Complete")})
.catch(error=>alert(error));
        }else{
            alert("Password cannot be blank.")
        }
    }
    return (
        <>
        <div style={{backgroundColor:'#fff', minHeight:800}}>
            
            <Header />
            <div className='info preds'>
            
                <h3 className='heading'>Edit Profile</h3>
            <div className="login-box-body">
                <form onSubmit={(e)=>{e.preventDefault();handleSubmit()}}>
                    <label for='name'>Name</label>
                    <input type="text" name='name' className='form-control' value={name} onChange={(e)=>setName(e.target.value)} placeholder="Name" />
                    <label for='address'>Address</label>
                    <input type="text" name='address' className='form-control' value={address} onChange={(e)=>setAddress(e.target.value)} placeholder="Address" />
                    <label for='email'>Email</label>
                    <input type="email" name='email' className='form-control' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
                    <label for='contact'>Contact</label>
                    <input type="number" name='contact' className='form-control' value={contact} onChange={(e)=>setContact(e.target.value)} placeholder="Contact" />
                    <label for='url'>URL</label>
                    <input type="url" name='url' className='form-control' value={uurl} onChange={(e)=>setUurl(e.target.value)} placeholder="URL" />
                    <label for='url'>Password</label>
                    <input type="password" required='required' className="form-control" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Current Password" /><br />
                    <input type="submit" className="btn btn-primary" value="Change" />
                </form>
            </div>
            
            </div>
            <Footer />
            </div>
            
        </>
    )
}

export default EditProfile
