import React from 'react'
const dates=['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
function DateDD(props) {
    const dd=dates.map((x, i)=> <option key={i} value={x} selected={props.date==x && 'selected'}>{x}</option>)
    // const dd=null;
    return (
        <div>
            <select className="datedd" onChange={(e)=>props.updateDate(e.target.value)}>
                
                {dd}
                
            </select>
        </div>
    )
}

export default DateDD