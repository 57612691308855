import React, {useState} from 'react'
import { useSelector } from 'react-redux';
// import Tooltip from 'react-tooltip-lite';
import * as Numerology from './calc/Numerology';
import ChaldeanGrid from './ChaldeanGrid';
import ChaldeanNameAnalysis from './ChaldeanNameAnalysis';
import PersonalDayMonthYear from './PersonalDayMonthYear';
import ChaldeanYourNumber from './ChaldeanYourNumber';

function Chaldean() {
    const [page, setPage]=useState('grid')
    const native=useSelector((state:any)=>state.nativeReducer);
    
    const renderSwitch=()=>{
        switch(page){
            case 'grid':
                return <ChaldeanGrid />
            case 'name':
                return <ChaldeanNameAnalysis />
            case 'personal':
                return <div className="info" style={{padding:10}}><PersonalDayMonthYear dd={new Date(native.dob).getDate().toString()} mm={new Date(native.dob).getMonth().toString()} year={new Date(native.dob).getFullYear().toString()} /></div>
            case 'your_numbers':
                return <ChaldeanYourNumber />
        } 
    }
    return (
        <div>
            <aside className="col-md-2 leftmenu">
                <h5 className="linkstyle" onClick={()=>setPage('grid')}>Grid</h5>
                <h5 className="linkstyle" onClick={()=>setPage('name')}>Name Analysis</h5>
                <h5 className="linkstyle" onClick={()=>setPage('personal')}>Personal Year / Month / Day</h5>
                <h5 className="linkstyle" onClick={()=>setPage('your_numbers')}>Your Numbers</h5>
            </aside>
            <aside className="col-md-10 contents">
            {renderSwitch()}
            </aside>
            
            
        </div>
    )
}

export default Chaldean
