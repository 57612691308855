import React, {useState} from 'react'
import { API_URL } from './Global';
import Header from './components/Header';
import Footer from './components/Footer';
function ChangePassword() {
    const [opass, setOpass]=useState('');
    const [password, setPassword]=useState('');
    const [cpass, setCpass]=useState('');
    const handleSubmit=()=>{
        if(password===cpass){
        fetch(API_URL+'user/changepass.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify({"opass":opass,"password":password,"cpass":cpass,"uid":sessionStorage.getItem('uid')})
})
.then((response)=>response.data)
.then((data)=>alert("Password Changed"))
.catch(error=>alert(error));
        }else{
            alert("Password and Confirm Password must match.")
        }
    }
    return (
        <div>
            <Header />
            <div className="login-box">
            <div className="login-box-body">
                <form onSubmit={(e)=>{e.preventDefault();handleSubmit()}}>
                    <input type="password" className="form-control" value={opass} onChange={(e)=>setOpass(e.target.value)} placeholder="Current Password" /><br />
                    <input type="password" className="form-control" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="New Password" /><br />
                    <input type="password" className="form-control" value={cpass} onChange={(e)=>setCpass(e.target.value)} placeholder="Confirm Password" /><br /><br />
                    <input type="submit" className="btn btn-primary" value="Change" />
                </form>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default ChangePassword
