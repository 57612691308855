import React, {useState} from 'react'
import * as Numerology from './calc/Numerology.js';
import {useSelector} from 'react-redux';
import PredPrint from './PredPrint';
import Popup from './Popup';

function LoshuMissingh() {
    const [isSeen, setIsSeen]=useState(false);
    const [contents, setContents]=useState("");
    
    const Native=useSelector((state)=>state.nativeReducer);
    let grid='';
    // grid=new Date(Number(Native.dob)).getDate().toString()+(new Date(Number(Native.dob)).getMonth()+1).toString()+new Date(Number(Native.dob)).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Number(Native.dob)).getDate().toString())+Numerology.getLifePathNumber(new Date(Number(Native.dob)).getDate().toString(),(new Date(Number(Native.dob)).getMonth()+1).toString(),new Date(Number(Native.dob)).getFullYear().toString())
    // grid=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]+Numerology.kuaNumber(new Date(Native.dob).getFullYear(), Native.gender)
    grid=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())[1]+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]+Numerology.kuaNumber(new Date(Native.dob).getFullYear(), Native.gender)
    return (
        <div>
            <h3 className="heading">Loshu Missing</h3>
            <div className="info preds">
            <div className="col-md-4">
                
            {Native.dob && Numerology.loshuGrid.map((x)=>x.map((y)=><div className='col-xs-4' style={{border:'1px #999 solid', height:100, textAlign:'center', padding:25, cursor:'pointer'}}>{!grid.split('').includes(y.toString()) ? <span dangerouslySetInnerHTML={{__html: y}} /> : null}</div>))}
                {/* <div style={{fontSize:12, paddingTop:10}}>
                <i style={{width:10, height:10}} className="col-xs-4 btn btn-info btn-circle"></i> Birthdate Number<br/><br/>
                <i style={{width:10, height:10}} className="col-xs-4 btn btn-warning btn-circle"></i> Lifepath Number<br/><br/>
                <i style={{width:10, height:10}} className="col-xs-4 btn btn-danger btn-circle"></i> Kua Number<br/><br/>
                </div> */}
            </div>
            <div className="col-md-8">
            {/* {Numerology.getPythagoreanCombo(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1])} */}
                {/* {Numerology.getAllNumbers(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1, (new Date(localStorage.dob).getFullYear().toString().split('')),Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1],Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1]).map(x=><PredPrint number={x} prediction={Numerology.getPythagoreanCharacteristics(x)} />)} */}
                <h4>Missing Numbers</h4>
                {[1,2,3,4,5,6,7,8,9].filter((x)=>!grid.includes(x)).map(y=><PredPrint missing={true} number={y} prediction={Numerology.getLoshuMissing(y)}/>)}
                {/* <span dangerouslySetInnerHTML={{__html: Numerology.getSmallArrow(grid)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getThoughtPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getWillPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getActionPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getMentalPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getEmotionalPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getPracticlePlane(planes)}} /> */}
            </div>
            <p className="clearfix"></p>
            </div>
            {isSeen ? <Popup close={()=>setIsSeen(false)} contents={contents} /> : null}
        </div>
    )
}

export default LoshuMissingh
