import React from 'react'
const months=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
function DateMM(props) {
    let mm=months.map((m,i)=><option value={i} key={i} selected={props.month===i && 'selected'}>{m}</option>)
    return (
        <div>
            <select className="datedd" onChange={(e)=>props.updateMonth(e.target.value)}>
                {mm}
            </select>
        </div>
    )
}

export default DateMM
