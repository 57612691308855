import { Console } from "console";

export const API_URL='https://numero.authenticastro.in/api/';
// export const API_URL='http://localhost:8888/api/';
export const isValidDate=(dd:string,mm:number,yyyy:string)=>{
    let r:boolean=true;
    let s=yyyy+'-'+(mm+1).toString()+'-'+dd;
    console.log(Date.parse(s));
    if(new Date(s).toString()==="Invalid Date"){
        r=false;
    }
    // if(mm===1){
    //     console.log('1');
    //     if(Number(yyyy)%4===0 || Number(yyyy)%400===0){
    //         if(Number(Number(dd)>29)){
    //             r=false;
    //         }
    //     }else if(Number(dd)>28){
    //         r=false;
    //     }
    // }else if(mm===3 || mm===5 || mm===8 || mm===10 || mm===12){
    //     console.log('3');
    //     if(Number(dd)>30){
    //         r=false;
    //     }
    // }
    // console.log(Number(dd),mm,yyyy);
    console.log(r);
}