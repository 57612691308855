import React, {useState} from 'react';
import * as Numerology from './calc/Numerology' 
import {useSelector} from 'react-redux';
import Popup from './Popup';

function ChaldeanYourNumber() {
  
    const [isSeen, setIsSeen]=useState(false);
    const [contents, setContents]=useState("");
    const native=useSelector((state)=>state.nativeReducer);
    return (
        <>
        <h3 className="heading">Your Numbers</h3>
        <div className="row info preds">
        <div className="col-xs-6"><span className="numhead">Moolank <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('birthdate')); setIsSeen(true);}}></i>: {Numerology.getLifePathNumber(new Date(native.dob).getDate())[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Bhagyank <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('lifepath')); setIsSeen(true);}}></i>: {Numerology.getLifePathNumber(new Date(native.dob).getDate(),new Date(native.dob).getMonth()+1,new Date(native.dob).getFullYear())[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Total Alphabet Count <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('totalalphabet')); setIsSeen(true);}}></i>: {Numerology.getAlphaCountNumber(native.fname+native.mname+native.lname)}</span></div>
            <div className="col-xs-6"><span className="numhead">First Name Alphabet Count <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('firstnamealphabet')); setIsSeen(true);}}></i>: {Numerology.getAlphaCountNumber(native.fname)}</span></div>
            <div className="col-xs-6"><span className="numhead">First Name Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('firstname')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getChaldeanCompoundNumber(native.fname))[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">First Name Compound Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('firstnamecompound')); setIsSeen(true);}}></i>: {Numerology.getChaldeanCompoundNumber(native.fname)}</span></div>            
            <div className="col-xs-6"><span className="numhead">Compound Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('compound')); setIsSeen(true);}}></i>: {Numerology.getChaldeanCompoundNumber(native.fname+native.mname+native.lname)}</span></div>
            <div className="col-xs-6"><span className="numhead">Name Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtbirthdate')); setIsSeen(true);}}></i>: {Numerology.singleDigit(Numerology.getChaldeanCompoundNumber(native.fname+native.mname+native.lname))[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Month Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('karmicdebtexpression')); setIsSeen(true);}}></i>: {Numerology.singleDigit(new Date(native.dob).getMonth()+1)[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Year Number <i className="glyphicon glyphicon-info-sign" onClick={()=>{setContents(Numerology.getDefination('year')); setIsSeen(true);}}></i>: {Numerology.singleDigit(new Date(native.dob).getFullYear())[1]}</span></div>
            <div className="col-xs-6"><span className="numhead">Sun Sign (Western) <i className="glyphicon glyphicon-info-sign"></i>: {Numerology.getZodiacWestern(native.dob)}</span></div>
            <div className="col-xs-6"><span className="numhead">Sun Sign (Eastern) <i className="glyphicon glyphicon-info-sign"></i>: {Numerology.getZodiac(native.dob)}</span></div>
        </div>
        {isSeen ? <Popup close={()=>setIsSeen(false)} contents={contents} /> : null}
        </>
    )
}

export default ChaldeanYourNumber;
