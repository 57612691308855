const initialState={
    uid:!sessionStorage.getItem('uid') ? -1 : sessionStorage.getItem('uid'),
    user:null
}
const userReducer=(state:any=initialState, action:any)=>{
    switch(action.type){
        case 'LOGIN':
            return {user:action.userdata};
        default:
            return {user:{}}
    }
}
export default userReducer;