import React from 'react';
import * as Numerology from './calc/Numerology';
import {useSelector} from 'react-redux';

function Health() {
    const Native=useSelector((state)=>state.nativeReducer);
    const prakriti=Numerology.getKaphaPittaVatta(Native.dob);
    return (
        <>
        <h3 className='heading'>Health</h3>
        <div className='info preds'>
        <div className="alert alert-danger">Prakriti: {prakriti}</div>
        <div>
            Health is the key point in anyone’s life. We will be able to achieve anything if we are in good health. Most of the time health is directly impacted by our food and thought. Our lifestyle is responsible for the kind of health we experience. Apart from all these, our numbers play a very crucial role in shaping our health. We are directly affected with the impact of all these life changing numbers.
            <b>As per Birthdate number:</b> {Numerology.getHelth(Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1])}
            <b>As per Lifepath number:</b> {Numerology.getHelth(Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1])}
            <b>As per Ayurveda you have <i><u>{prakriti}</u></i> issue in your body.</b>
            <h4 className="text-purple">Issue you may face</h4>
                <ol>
                {Numerology.getHealthIssue(prakriti).map((x, i)=><li key={i}>{x}</li>)}
                </ol>
        </div>
        <div className='pagebreak'></div>
        <div><h4 className="text-purple">Suggestions</h4>
                <ol>
                {Numerology.getHealthSuggestion(prakriti).map((x, i)=><li key={i}>{x}</li>)}
                </ol>
                </div>
        </div>
            
        </>
    )
}

export default Health
