import React, {useState} from 'react';
import {updateAdviceData} from './actions/profile';
import {useSelector, useDispatch} from 'react-redux';
function NumerologistAdvice() {
    const [advice, setAdvice]=useState(localStorage.getItem('advice'));
    const dispatch=useDispatch();
    return (
        <div>
            <form onSubmit={(e)=>{e.preventDefault(); dispatch(updateAdviceData(localStorage.getItem('id'),advice));}}>
            <div className="form-group"><label>Numerologist Advice</label>
            <textarea cols='100' rows='10' className="form-control" onChange={(e)=>setAdvice(e.target.value)}>{advice}</textarea>
            </div>
            <button className="btn btn-primary screenonly">Submit</button>
            </form>
        </div>
    )
}

export default NumerologistAdvice
