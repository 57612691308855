import React from 'react';
import * as Numerology from './calc/Numerology';

function HeartExpressionPersonality() {

    const HDN=Numerology.heartDesireNumber(localStorage.getItem('fname'), localStorage.getItem('mname'), localStorage.getItem('lname'))[1];
    const HDNPreds=Numerology.heartDesireNumberPreds(HDN);
    const EXPN=Numerology.expressionNumber(localStorage.getItem('fname'), localStorage.getItem('mname'), localStorage.getItem('lname'))[1];
    const EXPNPreds=Numerology.expressionNumberPreds(EXPN);
    const PER=Numerology.personalityNumber(localStorage.getItem('fname'), localStorage.getItem('mname'), localStorage.getItem('lname'))[1];
    const PERPreds=Numerology.personalityNumberPreds(PER);
return <>
<h3 className='heading'>Heart Desire / Expression / Personality</h3>
<div className='info preds'>
<h3>Heart Desire Number</h3>
<p>Heart desire number tells about your inner voice. It gives the details of what you want to achieve in his life. If the profession has been chosen according to your heart desire number then it always takes you towards ultimate success and satisfaction. This number plays a very important role in your life even if the same number is not available in your birth numbers.</p>
<p>Your Heart Desire number is {HDN}: {HDNPreds}</p>
<h3>Expression Number</h3>
<p>Expression  number talks about the way you wish to follow in life. It talks about the path you should choose to achieve your life purpose. This plays very important role in order to achieve your life purpose with a meaningful journey.</p>
<p>Your Expression number is {EXPN}: {EXPNPreds}</p>
<h3>Personality Number</h3>
<p>Personality number gives an idea as what others think about you. With the help of this number we can know the traits of our public profile and act accordingly. The knowledge of personality numbers empowers you to develop cordial relations to others and this increases understanding between people.</p>
<p>Your Personality number is {PER}: {PERPreds}</p>
</div>
  </>;
}

export default HeartExpressionPersonality;
