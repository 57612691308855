import React, {useState} from 'react'
import * as Numerology from './calc/Numerology.js';
import {useSelector} from 'react-redux';

function SmallArrow() {
    const [isSeen, setIsSeen]=useState(false);
    const [page, setPage]=useState('grid');
    const [bn, setBN]=useState(true);
    const [ln, setLN]=useState(true);
    const [kua, setKua]=useState(true);
    const chk=['b','l','k'];
    const [contents, setContents]=useState("");
    
    const Native=useSelector((state)=>state.nativeReducer);
    const getTimes=(y)=>{
        // console.log(y,'=>',getClass(Number(y)[1]));
        let p1='';
        let p2='';
        grid.split('').map(x=>Number(x)===y ? p2=p2+`<span class="bn ${getClass(y)}">${x}</span>` : null);
        grid.split('').map(x=>Number(x)===y ? p1=p1+x : '');
        return [p1,p2];
    }
    const getClass=(n)=>{
        // if(chk.includes("b") && n===Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1]){
        //     let i=chk.indexOf("b");
        //     chk.splice(i,1);
        //     return 'btn btn-info btn-circle';
        // }else if(chk.includes("l") && n===Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]){
        //     let i=chk.indexOf("l");
        //     chk.splice(i,1);
        //     return 'btn btn-warning btn-circle';
        // } else if(chk.includes("k") && n===Numerology.kuaNumber(new Date(Native.dob).getFullYear().toString(),Native.gender)){
        //     let i=chk.indexOf("k");
        //     chk.splice(i,1);
        //     return 'btn btn-danger btn-circle';
        // } 
        return '';
    }
    let grid='';
    // grid=new Date(Number(Native.dob)).getDate().toString()+(new Date(Number(Native.dob)).getMonth()+1).toString()+new Date(Number(Native.dob)).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Number(Native.dob)).getDate().toString())+Numerology.getLifePathNumber(new Date(Number(Native.dob)).getDate().toString(),(new Date(Number(Native.dob)).getMonth()+1).toString(),new Date(Number(Native.dob)).getFullYear().toString())
    grid=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]+Numerology.kuaNumber(new Date(Native.dob).getFullYear(), Native.gender)
    return (
        <>
        <h3 className='heading'>Small Arrow</h3>
        <div className="info preds">
            <div className="col-md-3" style={{transform:'rotate(0deg)', padding:10}}>{Native.dob && [[0,9,0],[3,0,7],[0,1,0]].map((x)=>x.map((y)=><div className='col-xs-4' style={{border:'2px #999 solid', backgroundColor: y>0 ? '#800080' : '#fff', color:'#ffffff', height:40, textAlign:'center', padding:25, cursor:'pointer'}}>{grid.split('').includes(y.toString()) ? <div onClick={()=>{setContents(Numerology.getPreds(getTimes(y)[0], false));setIsSeen(true)}} dangerouslySetInnerHTML={{__html: getTimes(y)[1]}} /> : y>0 ? <div style={{color:'#444', transform:'rotate(0deg)'}} onClick={()=>{setContents(Numerology.getPreds(y, true));setIsSeen(true)}}>{y}</div> : ""}</div>))}</div>
            <div className="col-md-1">&nbsp;</div>
            <div className="col-md-8" style={{padding:10}}>
            <span dangerouslySetInnerHTML={{__html: Numerology.getSmallArrow(grid)}} />
            </div>
        </div>
        </>
    )
}

export default SmallArrow
