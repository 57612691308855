    import React from 'react';
    import * as preds from './preds';
    import predictionhindi from './prediction_hindi.json';
    import predictionenglish from './prediction_english.json';
    const prediction=sessionStorage.getItem('language')==='english' ? predictionenglish : predictionhindi ;
    const KarmicDebtNumber=[13,14,16,19];
    const MasterNumber=[11,22,33];
    const SingleDigitNumber=[1,2,3,4,5,6,7,8,9];
    const PythagoreanLetters=['AJS','BKT','CLU','DMV','ENW','FOX','GPY','HQZ','IR'];
    const ChaldeanLetters=['AJIQY','BKR','CGLS','DMT','HENX','UVW','OZ','PF'];
    const vowels=['A', 'E', 'I', 'O', 'U'];
    const ChallengeCycle=[[35,44,53],[34,43,52],[33,42,51],[32,41,50],[31,40,49],[30,39,48],[29,38,47],[28,37,46],[27,36,45]];
    const PinnacleCycle=[[35,44,53],[34,43,52],[33,42,51],[32,41,50],[31,40,49],[30,39,48],[29,38,47],[28,37,46],[27,36,45]];
    const loshuGrid=[[4,9,2],[3,5,7],[8,1,6]];
    const chaldeanGrid=[[3,1,9],[6,7,5],[2,8,4]];
    const LuckyCareer=[1,4,5];
    const LuckyFinance=[1,4,5,8];
    const LuckyFamily=[2,6];
    //Loshu Plane
    const MentalPlane=[4,9,2];
    const EmotionalPlane=[3,5,7];
    const PracticlePlane=[8,1,6];
    const ThoughtPlane=[4,3,8];
    const WillPlane=[9,5,1];
    const ActionPlane=[2,7,6];
    const ChaldeanPred=['3-1', '1-9', '3-1-9', '3-6', '6-2', '3-6-2', '1-7', '1-8', '1-7-8', '9-5', '5-4', '9-4', '9-5-4', '2-8', '8-4', '2-8-4', '2-7-9', '8-4-9', '6-7', '7-5', '6-7-5'];
    // const PythaSuitable=['Primary Suitable', 'Secondary Suitable', 'Primary Avoidable', 'Secondary Avoidable'];
    const PythaSuitable=['Excellent', 'Favourable', 'Avoidable', 'Avoidable'];
    const Suitable=['Favourable', 'Avoidable'];
    const getAntiSuitable=(n)=>{
        console.log("Anti=",n);
        if(n!==undefined){
        let bd=getBirthdayNumber(new Date(localStorage.getItem('dob')).getDate())[1];
        let lp=getLifePathNumber(new Date(localStorage.getItem('dob')).getDate(),new Date(localStorage.getItem('dob')).getMonth()+1,new Date(localStorage.getItem('dob')).getFullYear())[1];
        console.log('bdlp', Suitable[getSuitable(bd,n)]);
        // return Suitable[getSuitable(bd,n)];
        return Suitable[getSuitable(bd,n)]==="Favourable" && Suitable[getSuitable(lp,n)]==="Favourable" ? "Fovourable" : "Avoidable";
        // console.log(bd,'=>',lp, '=>',n);
        }
    }
    //ps, ss, pa, sa
    const PythagoreanSuitable=[['1','356','8','2479'],['1','356','2','4789'],['1','35','6','24789'],['6', '135', '4', '2789'],['1','356','4','2789'],['1', '56', '3', '24789'],['6','135','8','2479'],['6','35','1','2489'],['1','356','4','2789']];
    const AntiNumber=[[8],[4,8,9],[6],[2,9],[],[3],[],[1,2],[2,4]]
    const singleDigit=(num)=>{
        let ans=[0,0];
        if(num){
        let d;
        let sum=0;
        while(true){
            if(num<10){
                ans[1]=num;
                break;
            }
            if(KarmicDebtNumber.includes(num))
                ans[0]=num;
            if(MasterNumber.includes(num))
                ans[0]=num;
            d=num.toString().split('');
            sum=0;
            d.map(d1=>(sum+=Number(d1)));
            num=sum;
            // num=Number(d[0])+Number(d[1]);
        }
    }
        return ans;
    }
    const singleDigitMobile=(num)=>{
        let ans=[0,0];
        if(num){
        let d;
        let sum=0;
        while(true){
            if(num<10){
                ans[1]=num;
                break;
            }
            ans[0]=num;
            d=num.toString().split('');
            sum=0;
            d.map(d1=>(sum+=Number(d1)));
            num=sum;
            // num=Number(d[0])+Number(d[1]);
        }
    }
        return ans;
    }
    const getPythagoreanSuitable=(d,n)=>{
        let s=0;
        PythagoreanSuitable[d-1].map((x,i)=>x.split('').map(y=>Number(y)===n ? s=i: null));
        return s;
    }
    const getSuitable=(d,n)=>{
        let s=0;
        KarmicDebtNumber.includes(n[0]) || AntiNumber[d-1].includes(n[1]) ? s=1 : s=0;
        return s;
    }
    const getChaldeanSuitable=(d,n)=>{
        let s=0;
        PythagoreanSuitable[d-1].map((x,i)=>x.split('').map(y=>Number(y)===n ? s=i: null));
        return s;
    }
    const getChallengeNumber=(dd, mm, yyyy, year)=>{
        mm+=1;
        let lpno=getLifePathNumber(dd.toString(),mm.toString(),yyyy.toString());
        let f=4;
        let age=year-yyyy;
        console.log(lpno);
        lpno[1]>0 && ChallengeCycle[lpno[1]-1].toString().split(',').map((x,i)=>{return (age <= Number(x) && f===4) ? f=i+1 : null;});
        let a=0;
        switch(f){
            case 1:
                a=singleDigit(mm)[1]-singleDigit(dd)[1];
                break;
            case 2:
                a =singleDigit(dd)[1]-singleDigit(yyyy)[1];
                break;
            case 3:
                let f=singleDigit(mm)[1]-singleDigit(dd)[1];
                let s=singleDigit(dd)[1]-singleDigit(yyyy)[1];
                if(f<0) f*=-1;
                if(s<0) s*=-1;
                a=f-s;
                break;
            case 4:
                a=singleDigit(mm)[1]-singleDigit(yyyy)[1];
        }
        return a <0 ? a*-1 : a;
    }
    const pinnacleChallangeCycle=(dd,mm,yyyy)=>{
        let lpno=getLifePathNumber(dd.toString(),mm.toString(),yyyy.toString());
        return (ChallengeCycle[lpno[1]-1].toString().split(','));
    }
    const getSmallArrow=(grid)=>{
        let str="<hr />";
        if(grid.includes('3') && grid.includes('1')){
            // str+="<h4 class='predhead' style='color:orange;'>You have Small Arrow numbers (3->1)</h4><p>This small arrow guarantees detailing of anything. You wish to know in detail whatever comes in your way. You are outspoken and have a tendency of forgetting your own words or statement. A promise does not hold value before you.</p>";
            str+="<p>"+prediction.small_arrow["3-1"]+"</p>";
        }
        if(grid.includes('3') && grid.includes('9')){
            // str+="<h4 class='predhead' style='color:orange;'>You have Small Arrow numbers (3->9)</h4><p>This combination gives a fighter attitude. You become aggressive without any visible or concrete reason. This combination attracts litigation and disputes.</p>";
            str+="<p>"+prediction.small_arrow["3-9"]+"</p>";
        }
        if(grid.includes('9') && grid.includes('7')){
            // str+="<h4 class='predhead' style='color:orange;'>You have Small Arrow numbers (9->7)</h4><p>This combination makes you confident and attracts peace of mind. This makes you spiritual and calm. This gives you greater vision to understand and forecast events.</p>";
            str+="<p>"+prediction.small_arrow["9-7"]+"</p>";
        }
        if(grid.includes('7') && grid.includes('1')){
            // str+="<h4 class='predhead' style='color:orange;'>You have Small Arrow numbers (1->7)</h4><p>This combination assures deep research and new findings. You wish to explore new things. Less travelled path always attracts you.</p>";
            str+="<p>"+prediction.small_arrow["7-1"]+"</p>";
        }
        return str;
    }
    const getPinnacleNumber=(dd, mm, yyyy, year)=>{
        console.log(dd,mm,yyyy,year);
        mm+=1;
        let lpno=getLifePathNumber(dd.toString(),mm.toString(),yyyy.toString());
        let f=4;
        let age=year-yyyy;
        lpno[1]>0 && ChallengeCycle[lpno[1]-1].toString().split(',').map((x,i)=>{return (age <= Number(x) && f===4) ? f=i+1 : null;});
        let a=0;
        switch(f){
            case 1:
                a=singleDigit(dd)[1]+singleDigit(mm)[1];
                break;
            case 2:
                a =singleDigit(dd)[1]+singleDigit(yyyy)[1];
                break;
            case 3:
                let f=singleDigit(dd)[1]+singleDigit(mm)[1];
                let s=singleDigit(dd)[1]+singleDigit(yyyy)[1];
                a=(f+s);
                break;
            case 4:
                a=singleDigit(mm)[1]+singleDigit(yyyy)[1];
        }
        return singleDigit(a)[1];
    }
    const getLetter=(name, birthYear, currYear)=>{
        let num=name!==null && name!==undefined ? name.split('').map(x=>{ return pythagorean(x);}) : '';
        let i=-1;
        while(birthYear<=currYear){
            i===(num.length-1) ? i=0 : i++;
            birthYear=(Number(birthYear)+Number(num[i]));
        }
        return name!==null && name!==undefined ? name.split('')[i] : '';
    }
    const getYearDays=(y)=>{
        return y%4===0 ? 366 :365;
    }
    const getDasha=(dob)=>{
        // let ln=getLifePathNumber(new Date(dob).getDate(),new Date(dob).getMonth()+1,new Date(dob).getFullYear())[1];
        let ln=getBirthdayNumber(new Date(dob).getDate())[1];
        let d=new Date(dob);
        let dasha=[d.toDateString()];
        // let div=`<table className="table table-bordered"><thead><tr><th>Maha Dasha Number</th><th>Till Date</th></tr></thead><ttbody>`;
        for(let i=ln; i<=ln+10;i++){
            // div+=`<h3>${new Date(new Date(d).setDate(d+y*singleDigit(i)[1]))}</h3>`;
            // div+=`<h3>${new Date(d.setDate(d.getDate()+(y*Number(singleDigit(ln)[1])))).toDateString()}</h3>`;
            // div+=`<tr><td>${singleDigit(i)[1]}</td><td>${new Date(d.setDate(d.getDate()+getYearDays(d.getFullYear())*singleDigit(i)[1])).toDateString()}</td></tr>`;
            // ln++;
            dasha.push(new Date(d.setDate(d.getDate()+getYearDays(d.getFullYear())*singleDigit(i)[1])).toDateString())
        }
        // div+=`</tbody></table>`;
        return dasha;
    }
    const getAntarDasha=(s,e,i,dob)=>{
        let ln=getLifePathNumber(new Date(dob).getDate(),new Date(dob).getMonth()+1,new Date(dob).getFullYear())[1];
        let dasha=[new Date(s).toDateString()];

        while(s!==e){

        }
        return dasha;
    }
    const getEssenceNumber=(fname, mname, lname, birthYear, currYear)=>{
        return singleDigit(pythagorean(getLetter(fname,birthYear,currYear))+pythagorean(getLetter(mname,birthYear,currYear))+pythagorean(getLetter(lname,birthYear,currYear)));
    }
    const getBirthdayNumber=(dd)=>{
        return singleDigit(Number(dd));
    }
    const getPersonalYear=(dd,mm, currYear)=>{
        return singleDigit((singleDigit(dd)[1]+singleDigit(mm+1)[1]+singleDigit(currYear)[1]))[1];
    }
    const getLifePathNumber=(dd, mm, yyyy)=>{
        let dt=singleDigit(Number(dd))[1];
        let mt=singleDigit(Number(mm))[1];
        let yt=singleDigit(Number(yyyy))[1];
        return singleDigit((dt+mt+yt));
    }
    const pythagorean=(letter)=>{
        let v=0;
        PythagoreanLetters.forEach((l, i) => {
           return l.split('').includes(letter && letter.toUpperCase()) ? v=(i+1): null;
        });
        console.log('letter ',letter,v);
        return v;
    }
    const pythagoreanCalc=(str)=>{
        console.log("Calc: ",str);
        if (str===undefined) return singleDigit(0);
        let s=str.split('');
        let sum=0;
        s.map((l)=>sum+=pythagorean(l));
        return singleDigit(sum);
    }
    const chaldean=(letter)=>{
        let v=0;
        ChaldeanLetters.forEach((l, i) => {
           return l.split('').includes(letter.toUpperCase()) ? v=(i+1): null;
        });
        return v;
    }
    const chaldeanCalc=(str)=>{
        let s=str.split('');
        let sum=0;
        s.map((l)=>sum+=chaldean(l));
        return singleDigit(sum);
    }
    const expressionNumber=(fname, mname, lname)=>{
        let fcalc=pythagoreanCalc(fname);
        let mcalc=pythagoreanCalc(mname);
        let lcalc=pythagoreanCalc(lname);
        return singleDigit((fcalc[1]+mcalc[1]+lcalc[1]));
    }
    const expressionNumberPreds=(e)=>{
        return prediction.expressionnumber[e];
    }
    const heartDesireNumber=(fname, mname, lname)=>{
        if (fname===undefined && mname===undefined && lname===undefined) return singleDigit(0);
        let sum=0;
        (fname+mname+lname).split('').map((l)=>vowels.includes(l.toUpperCase()) && (sum+=pythagorean(l)));
        console.log((fname+mname+lname));
        return singleDigit(sum);
    }
    const heartDesireNumberPreds=(e)=>{
        return prediction.heartdesire[e];
    }
    const personalityNumber=(fname, mname, lname)=>{
        if (fname===undefined && mname===undefined && lname===undefined) return singleDigit(0);
        let sum=0;
        // (fname+mname+lname).split('').map((l)=>!vowels.includes(l.toUpperCase()) && (sum+=pythagorean(l)));
        (fname+mname+lname).split('').map((l)=>!vowels.includes(l.toUpperCase()) && (sum+=pythagorean(l)));
        console.log((fname+mname+lname));
        return singleDigit(sum);
    }
    const getFirstVowel=()=>{
        let name=localStorage.getItem('fname')+localStorage.getItem('mname')+localStorage.getItem('lname');
        return pythagoreanCalc(name.split("").filter(x=>vowels.includes(x.toUpperCase()))[0])[1];
    }
    const personalityNumberPreds=(e)=>{
        return prediction.personality[e];
    }
    const kuaNumber=(year, gender)=>{
        // console.log(year);
        switch(gender){
            case 'Male':
                return singleDigit(11-singleDigit(year)[1])[1];
            case 'Female':
                return singleDigit(4+singleDigit(year)[1])[1];
            case 'Other':
                return singleDigit(8+singleDigit(year)[1])[1];
        }
    }
    const loshuMissing=(num)=>{
        return prediction.loshu.missing.hasOwnProperty(num) ? 
        <div>
            Missing Number <strong>{num}</strong> represents {prediction.loshu.missing[num]}
            <br />
            Remedy: {prediction.loshu.missingremedy[num]}
        </div> : '';
    }
    const getLoshuMissing=(num)=>{
        return prediction.loshu.missing[num];
    }
    const getLoshuVastu=(num)=>{
        return prediction.vastu[num];
    }
    const getPythagoreanCharacteristics=(num)=>{
        return prediction.characteristics[num];
    }
    const getPythagoreanCombo=(b,l)=>{
        let i=b+'-'+l;
        return prediction.driverconductorcombo[i];
    }
    const luckyDates=(d)=>{
        return prediction.lucky[d].dates;
    }
    const luckyDays=(d)=>{
        return prediction.lucky[d].days;
    }
    const luckyColours=(d)=>{
        return prediction.lucky[d].colour;
    }
    const getImportantCareerYear=(dob)=>{
        let d=new Date(dob).getDate();
        let m=new Date(dob).getMonth()+1;
        let i=new Date(dob).getFullYear();
        let y=Array.from(new Array(100), (val,index)=>i+index).filter(x=>LuckyCareer.includes(getPersonalYear(d,m,x)));
        return y;
    }
    const getImportantFinanceYear=(dob)=>{
        let d=new Date(dob).getDate();
        let m=new Date(dob).getMonth()+1;
        let i=new Date(dob).getFullYear();
        let y=Array.from(new Array(100), (val,index)=>i+index).filter(x=>LuckyFinance.includes(getPersonalYear(d,m,x)));
        return y;
    }
    const getImportantFamilyYear=(dob)=>{
        let d=new Date(dob).getDate();
        let m=new Date(dob).getMonth()+1;
        let i=new Date(dob).getFullYear();
        let y=Array.from(new Array(100), (val,index)=>i+index).filter(x=>LuckyFamily.includes(getPersonalYear(d,m,x)));
        return y;
    }
    const getMarriage=(b, l)=>{
        return prediction.marriagenew[`${b}-${l}`];
    }
    const getRemedyNumbers=(dob)=>{
        let d=new Date(dob).getDate().toString()+(new Date(dob).getMonth()+1).toString()+new Date(dob).getFullYear().toString();
        d=(d+getBirthdayNumber(new Date(dob).getDate())[1]);
        d=d+getLifePathNumber(new Date(dob).getDate(),new Date(dob).getMonth()+1,new Date(dob).getFullYear())[1];
        // let r=[getBirthdayNumber(new Date(dob).getDate())[1],getLifePathNumber(new Date(dob).getDate(),new Date(dob).getMonth()+1,new Date(dob).getFullYear())[1]];
        let dd=d.split('').filter(x=>Number(x)!=0);
        let l=[1,2,3,4,5,6,7,8,9];
        let r=l.filter(x=>!dd.includes(x.toString()));
        return r;
    }
    const getOccuranceArray=(r)=>{
        let result={};
        let arr=[];
        result=r.reduce(function (acc, curr) {
            if(curr!=0){
            if (typeof acc[curr] === 'undefined') {
              acc[curr] = 1;
            } else {
              acc[curr] += 1;
            }
        }
            return acc;
          }, {});
          let s=Object.keys(result);
          s.map(x=>{
              if(x<=2){
                    if(result[x]===1)
                    arr.push(x);
                    else if(result[x]===2)
                    arr.push(x+x);
                    else if(result[x]>=3)
                    arr.push(x+x+x)
              }
              if(x>2){
                    if(result[x]===1)
                    arr.push(x);
                    else if(result[x]>=2)
                    arr.push(x+x);
                    
              }
                  
              }
          )
        return arr;
    }
    const getAllNumbers=(d,m,y,b,l)=>{
        let p=d.toString()+m.toString()+y.toString()+l.toString();
        if(d>9){p+=b.toString()};
        let r=getOccuranceArray(p.split('').sort());
        return r;
    }
    const getRemedy=(num)=>{
        return prediction.remedy[num];
    }
    const getPreds=(n, ismissing)=>{
        let str="<strong>Number "+n+"</strong><br />";
        str+=getPythagoreanCharacteristics(n);
        str+=ismissing ? "<br /><strong><small>Number is missing in your chart you should apply the following remedy:</small></strong><br />" : "<br /><strong><small>To maintain the energy of the number you can do following things:</small></strong><br />";
        str+=getRemedy(n>9 ? n.split('')[0] : n);
        return str;
    }
    const getThoughtPlane=(bd)=>{
        let str="<div class='planebox'><h1 class='planehead'>Thought Plane numbers are (4-3-8).</h1><br /><h1 class='planesubhead'>Your chart has ";
        let b=ThoughtPlane.filter(x=>bd.split('').includes(x.toString()));
        b.length===0 ? str+="nothing" : str+=b.toString();
        str+= " from the plane.</h1><br />";
        str+="<div class='col-md-3'><table style='background-color:#fff;' class='table table-bordered table-stripped'><tr><th>4</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr><tr><th>3</th><th></th><th></th></tr><tr><th>8</th><th></th><th></th></tr></table></div><p class='planecontent'>"+prediction.loshuplane.thought+"</p>";
        str+="<br /><strong>Your Thought plane has "+Math.round((100/3)*b.length)+"% weightage.</strong><p class='clearfix'></p></div>"
        return str;
    }
    const getWillPlane=(bd)=>{
        let str="<div class='planebox'><h1 class='planehead'>Will Plane numbers are (9-5-1).</h1><br /><h1 class='planesubhead'>Your chart has ";
        let b=WillPlane.filter(x=>bd.split('').includes(x.toString()));
        b.length===0 ? str+="nothing" : str+=b.toString();
        str+= " from the plane.</h1><br />";
        str+="<div class='col-md-3'><table style='background-color:#fff;' class='table table-bordered table-stripped'><tr><th>&nbsp;&nbsp;&nbsp;</th><th>9</th><th>&nbsp;&nbsp;&nbsp;</th></tr><tr><th></th><th>5</th><th></th></tr><tr><th></th><th>1</th><th></th></tr></table></div><p class='planecontent'>"+prediction.loshuplane.will+"</p>";
        str+="<br /><strong>Your Will plane has "+Math.round((100/3)*b.length)+"% weightage.</strong><p class='clearfix'></p></div>"
        return str;
    }
    const getActionPlane=(bd)=>{
        let str="<div class='planebox'><h1 class='planehead'>Action Plane numbers are (2-7-6).</h1><br /><h1 class='planesubhead'>Your chart has ";
        let b=ActionPlane.filter(x=>bd.split('').includes(x.toString()));
        b.length===0 ? str+="nothing" : str+=b.toString();
        str+= " from the plane.</h1><br />";
        str+="<div class='col-md-3'><table style='background-color:#fff;' class='table table-bordered table-stripped'><tr><th>&nbsp;&nbsp&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>2</th></tr><tr><th></th><th></th><th>7</th></tr><tr><th></th><th></th><th>6</th></tr></table></div><p class='planecontent'>"+prediction.loshuplane.action+"</p>";
        str+="<br /><strong>Your Action plane has "+Math.round((100/3)*b.length)+"% weightage.</strong><p class='clearfix'></p></div>"
        return str;
    }
    const getMentalPlane=(bd)=>{
        let str="<div class='planebox'><h1 class='planehead'>Mental Plane numbers are (4-9-2).</h1><br /><h1 class='planesubhead'>Your chart has ";
        let b=MentalPlane.filter(x=>bd.split('').includes(x.toString()));
        b.length===0 ? str+="nothing" : str+=b.toString();
        str+= " from the plane.</h1><br />";
        str+="<div class='col-md-3'><table style='background-color:#fff;' class='table table-bordered table-stripped'><tr><th>4</th><th>9</th><th>2</th></tr><tr><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr><tr><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr></table></div><p class='planecontent'>"+prediction.loshuplane.mental+"</p>";
        str+="<br /><strong>Your Mental plane has "+Math.round((100/3)*b.length)+"% weightage.</strong><p class='clearfix'></p></div>"
        return str;
    }
    const getEmotionalPlane=(bd)=>{
        let str="<div class='planebox'><h1 class='planehead'>Emotional Plane numbers are (3-5-7).</h1><br /><h1 class='planesubhead'>Your chart has ";
        let b=EmotionalPlane.filter(x=>bd.split('').includes(x.toString()));
        b.length===0 ? str+="nothing" : str+=b.toString();
        str+= " from the plane.</h1><br />";
        str+="<div class='col-md-3'><table style='background-color:#fff;' class='table table-bordered table-stripped'><tr><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr><tr><th>3</th><th>5</th><th>7</th></tr><tr><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr></table></div><p class='planecontent'>"+prediction.loshuplane.emotional+"</p>";
        str+="<br /><strong>Your Emotional plane has "+Math.round((100/3)*b.length)+"% weightage.</strong><p class='clearfix'></p></div>"
        return str;
    }
    const getKarmicLessonNumber=(name)=>{
        name=name.toUpperCase();
        let t=[];
        ChaldeanLetters.map((x,i)=>{x.split('').map(y=>{name.split('').includes(y) && (!t.includes(i) && t.push(i))})});
        console.log(name);
        t.map(x=>console.log(t));
        name.split('').map((n)=>{})
        return [0,1,2,3,4,5,6,7].map(x=>{return !t.includes(x) ? (x+1) : null})
    }
    const getCompoundNumber=(name)=>{
        name=name.toUpperCase();
        let t=[];
        let s=0;
        PythagoreanLetters.map((x,i)=>{x.split('').map(y=>{name.split('').includes(y) && (!t.includes(i) && t.push(i))})});
        s=t.reduce((s,e)=>{ return s+e},0);
        return s;
    }
    // const getChaldeanCompoundNumber=(name)=>{
    //     name=name.toUpperCase();
    //     let t=[];
    //     let s=0;
    //     ChaldeanLetters.map((x,i)=>{x.split('').map(y=>{name.split('').includes(y) && (!t.includes(i) && t.push(i))})});
    //     s=t.reduce((s,e)=>{ return s+e},0);
    //     return s;
    // }
    const getChaldeanCompoundNumber=(name)=>{
        name=name.toUpperCase();
        let t=[];
        let s=0;
        name.split('').map(x=>ChaldeanLetters.map((y,i)=>{y.split('').includes(x) && t.push(i)}));
        s=t.reduce((s,e)=>{ return s+(e+1)},0);
        return s;
    }
    const getAlphaCountNumber=(name)=>{
        return name.split('').length;
    }
    const getPracticlePlane=(bd)=>{
        let str="<div class='planebox'><h1 class='planehead'>Practical Plane numbers are (8-1-6).</h1><br /><h1 class='planesubhead'>Your chart has ";
        let b=PracticlePlane.filter(x=>bd.split('').includes(x.toString()));
        b.length===0 ? str+="nothing" : str+=b.toString();
        str+= " from the plane.</h1><br />";
        str+="<div class='col-md-3'><table style='background-color:#fff;' class='table table-bordered table-stripped'><tr><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr><tr><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th><th>&nbsp;&nbsp;&nbsp;</th></tr><tr><th>8</th><th>1</th><th>6</th></tr></table></div><p class='planecontent'>"+prediction.loshuplane.practicle+"</p>";
        str+="<br /><strong>Your Practical plane has "+Math.round((100/3)*b.length)+"% weightage.</strong><p class='clearfix'></p></div>"
        return str;
    }
    const getPrashnaNumber=(n)=>{return prediction.prashna[n].split(",")}
    
    const getCompatibility=(n1,n2)=>{
        let i=n1+"-"+n2;
        console.log(i);
        return prediction.compatibility[i];
    }
    const getCompatibilityPreds=(str)=>{
        return prediction.compatibility_prediction[str];
    }
    const birthDateMonth=(bd, bm)=>{
        let bmt=["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let comppred=["Good", "Average", "Poor"];
        let i=-1;
        // console.log(prediction.Compatible_month[bd], "->", bm)
        // bd && prediction.Compatible_month[bd].map(x=>console.log(x));
        bd && bm && prediction.Compatible_month[bd].map((x,index)=>{return x.split(',').includes(bmt[bm]) ? i=index : null});
        return comppred[i];
    }
    const getHelth=(n)=>{
        return prediction.health[n];
    }
    const getZodiac=(dob)=>{
        let d=new Date(dob).getDate();
        let m=new Date(dob).getMonth()+1;
        if((d>=14 && m===4) || (d<=14 && m===5))
            return 'Aries';
        else if((d>=15 && m===5) || (d<=14 && m===6))
            return 'Taurus';
        else if((d>=15 && m===6) || (d<=16 && m===7))
            return 'Gemini';
        else if((d>=17 && m===7) || (d<=16 && m===8))
            return 'Cancer';
        else if((d>=17 && m===8) || (d<=16 && m===9))
            return 'Leo';
        else if((d>=17 && m===9) || (d<=16 && m===10))
            return 'Virgo';
        else if((d>=17 && m===10) || (d<=15 && m===11))
            return 'Libra';
        else if((d>=16 && m===11) || (d<=15 && m===12))
            return 'Scorpio';
        else if((d>=16 && m===12) || (d<=14 && m===1))
            return 'Saggiterius';
        else if((d>=15 && m===1) || (d<=13 && m===2))
            return 'Capricon';
        else if((d>=14 && m===2) || (d<=14 && m===3))
            return 'Aquarius';
        else if((d>=15 && m===3) || (d<=13 && m===4))
            return 'Pisces';
        
    }
    const getZodiacWestern=(dob)=>{
        let d=new Date(dob).getDate();
        let m=new Date(dob).getMonth()+1;
        if((d>=21 && m===3) || (d<=19 && m===4))
            return 'Aries';
        else if((d>=20 && m===4) || (d<=20 && m===5))
            return 'Taurus';
        else if((d>=21 && m===5) || (d<=21 && m===6))
            return 'Gemini';
        else if((d>=22 && m===6) || (d<=22 && m===7))
            return 'Cancer';
        else if((d>=23 && m===7) || (d<=22 && m===8))
            return 'Leo';
        else if((d>=23 && m===8) || (d<=22 && m===9))
            return 'Virgo';
        else if((d>=23 && m===9) || (d<=23 && m===10))
            return 'Libra';
        else if((d>=24 && m===10) || (d<=21 && m===11))
            return 'Scorpio';
        else if((d>=22 && m===11) || (d<=21 && m===12))
            return 'Saggiterius';
        else if((d>=22 && m===12) || (d<=19 && m===1))
            return 'Capricon';
        else if((d>=20 && m===1) || (d<=18 && m===2))
            return 'Aquarius';
        else if((d>=19 && m===2) || (d<=20 && m===3))
            return 'Pisces';
        
    }
    const getCapstone=()=>{
        let n=(localStorage.getItem('fname')+localStorage.getItem('mname')+localStorage.getItem('lname')).split("");
        // return pythagoreanCalc((localStorage.getItem('fname')+localStorage.getItem('mname')+localStorage.getItem('lname')).split("").sort((a,b)=>b-a)[0])[1]
        return pythagoreanCalc(n[n.length-1])[1];
    }
    const getKaphaPittaVatta=(dob)=>{
        debugger;
        let k=0;
        let v=0;
        let p=0;
        let z=getZodiac(dob);
        let bn=getBirthdayNumber(new Date(dob).getDate())[1];
        let l=getLifePathNumber(new Date(dob).getDate(),new Date(dob).getMonth()+1, new Date(dob).getFullYear())[1];
        let y=singleDigit(new Date(dob).getFullYear())[1];
        let m=singleDigit(new Date(dob).getMonth()+1)[1];
        if(z==="Gemini" || z==="Virgo" || z==="Capricon" || z==="Aquarius")
            v+=1;
        else if(z==="Aries" || z==="Leo" || z==="Scorpio")
            p+=1;
        else
            k+=1;
        if(bn===5 || bn===8 || bn===4)
            v+=1;
        else if(bn===1 || bn===9)
            p+=1;
        else
            k+=1;
        if(m===5 || m===8 || m===4)
            v+=1;
        else if(m===1 || m===9)
            p+=1;
        else
            k+=1;
        if(y===5 || y===8 || y===4)
            v+=1;
        else if(y===1 || y===9)
            p+=1;
        else
            k+=1;
        if(l===5 || l===8 || l===4)
            v+=2;
        else if(l===1 || l===9)
            p+=2;
        else
            k+=2;
        if(k>p && k>v)
            return "kapha";
        else if(p>k && p>v)
            return "pitta";
        else
            return "vatta";
    }
    const getHealthIssue=(p)=>{
        return prediction.healthissue[p];
    }
    const getHealthSuggestion=(p)=>{
        return prediction.healthsuggestion[p];
    }
    const chaldeanPreds=(grid)=>{
        let str=ChaldeanPred.filter((x, i)=>{
            let f=0;
            x.split('-').map((y)=>grid.includes(y) ? f++ : null);
            return (f===x.split('-').length) ? prediction.chaldean_preds[x] : null;
        });
        return str;
    }
    const getChaldeanPreds=(x)=>{
        return prediction.chaldean_preds[x]
    }
    const getPrediction = (num) => {
        switch(num){
            case "1":
                return prediction.pythagoreancareer['1'];
            case "2":
                return prediction.pythagoreancareer['2'];
            case "3":
                return prediction.pythagoreancareer['3'];
            case "4":
                return prediction.pythagoreancareer['4'];
            case "5":
                return prediction.pythagoreancareer['5'];
            case "6":
                return prediction.pythagoreancareer['6'];
            case "7":
                return prediction.pythagoreancareer['7'];
            case "8":
                return prediction.pythagoreancareer['8'];
            case "9":
                return prediction.pythagoreancareer['9'];
        }
        
    }
    const getDefination=(k)=>{
        return prediction.definations[k] || "We are sorry your request can not be deliver right now. Please visit again.";
    }
    // const getKeyByValue=(object) => {
        // return Object.keys(object).find(key => {console.log("Hidden: Key->", key); return object[key] === value}) 
    // }
    // const getKeyByValue=(arr, value) => {
        //return Object.keys(object).find(key => {return object[key] === value});
        // object.map(x=>{console.log(x)});
    //   }
    const hiddenPassionNumber=()=>{
        let name=localStorage.getItem('fname')+localStorage.getItem('mname')+localStorage.getItem('lname');
        let arr=name.split("").map(x=>pythagoreanCalc(x)[1]);
        const map = arr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
        let f=[...map.values()].sort((a,b)=>b-a);
        let h=[...map.entries()].filter(x=>x[1]===f[0])[0][0];
        return h;
        // let i=map.indexOf(f[0]);
        // console.log("Hidden: ",h);
        // console.log("Hidden: ",f[0]);
        // console.log("Hidden: ",[...map.entries()].filter(x=>x[1]===f[0])[0]);
        // console.log("Hidden: ", [...map.values()].sort((a,b)=>b-a));
        // console.log("Hidden: ", i);
    }
export {getBirthdayNumber, getZodiac,getHelth,getSuitable,singleDigitMobile,chaldeanPreds,getChaldeanPreds, getHealthIssue, getHealthSuggestion, getLifePathNumber, PythagoreanLetters,PythaSuitable,ChaldeanLetters,MentalPlane,EmotionalPlane,PracticlePlane,ThoughtPlane,WillPlane,ActionPlane,personalityNumber,chaldean, pythagoreanCalc, chaldeanCalc, singleDigit, loshuGrid,loshuMissing,chaldeanGrid,
    getDasha,getAntarDasha,getKaphaPittaVatta,getPrediction,getKarmicLessonNumber,pinnacleChallangeCycle,kuaNumber,expressionNumber,pythagorean,getPythagoreanSuitable, heartDesireNumber,getChallengeNumber,getPinnacleNumber,getLetter,getEssenceNumber,getPersonalYear,getPreds,
    getPythagoreanCharacteristics,getAntiSuitable,getChaldeanCompoundNumber,hiddenPassionNumber,getFirstVowel,getCapstone,getZodiacWestern,personalityNumberPreds,heartDesireNumberPreds,expressionNumberPreds,Suitable,birthDateMonth,getDefination,getCompatibilityPreds,getCompatibility,getSmallArrow,getLoshuVastu,getLoshuMissing,getAlphaCountNumber,getCompoundNumber,getPrashnaNumber,getPracticlePlane,getEmotionalPlane,getMentalPlane,getPythagoreanCombo,getThoughtPlane,getChaldeanSuitable,luckyDates,luckyDays,luckyColours,getImportantCareerYear,getImportantFinanceYear,getImportantFamilyYear,getMarriage,getRemedyNumbers,getRemedy,getAllNumbers,getWillPlane,getActionPlane}