import React from 'react';
import { useSelector } from 'react-redux';
import * as Numerology from './calc/Numerology.js';
import PersonalDayMonthYear from './PersonalDayMonthYear';
import YearAnalysis from './YearAnalysis';

function ChartAnalysis() {
    const native = useSelector((state: any) => state.nativeReducer);
    const year=["First", "Second", "Third", "Fourth"];
    const pinnacleYear=Numerology.pinnacleChallangeCycle(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear());
    return (
        <div>
            {console.log("Year: ",new Date(native.dob).getFullYear().toString())}
            <div className="heading">Chart Analysis</div>
            {
                native.dob === '' ? <h4>Please enter Native's Data.</h4> :
                    <div>
                        {/* Driver Number: {Numerology.getBirthdayNumber(new Date(Number(native.dob)).getDate().toString())}
                        Conductor Number: {Numerology.getLifePathNumber(new Date(Number(native.dob)).getDate().toString(), (new Date(Number(native.dob)).getMonth() + 1).toString(), new Date(Number(native.dob)).getFullYear().toString())} */}
                        <div className="col-md-5 info">
                            {/* <YearAnalysis dd={new Date(Number(native.dob)).getDate().toString()} mm={new Date(Number(native.dob)).getMonth().toString()} year={new Date(Number(native.dob)).getFullYear().toString()} fname={native.fname} mname={native.mname} lname={native.lname} /> */}
                            <YearAnalysis dd={new Date(native.dob).getDate().toString()} mm={new Date(native.dob).getMonth().toString()} year={new Date(native.dob).getFullYear().toString()} fname={native.fname} mname={native.mname} lname={native.lname} />
                            <hr />
                            <table className="table table-bordered preds">
                                <thead>
                                    <tr><th>Year</th><th>Pinnacle</th><th>Challange</th></tr>
                                </thead>
                                <tbody>
                                    
                            {pinnacleYear.map((x,i)=><tr><td>{year[i]} : {i===0 ? i : Number(pinnacleYear[i-1])+1} - {x}</td><td>{Numerology.getPinnacleNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth(),new Date(localStorage.dob).getFullYear(),(new Date(localStorage.dob).getFullYear()+(Number(x)-5)))}</td><td>{Numerology.getChallengeNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth(),new Date(localStorage.dob).getFullYear(),(new Date(localStorage.dob).getFullYear()+(Number(x)-5)))}</td></tr>)}
                        <tr><td>{year[3]} : {Number(pinnacleYear[pinnacleYear.length-1])+1} - </td><td>{Numerology.getPinnacleNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth(),new Date(localStorage.dob).getFullYear(),(new Date(localStorage.dob).getFullYear()+(Number(pinnacleYear[pinnacleYear.length-1])+5)))}</td><td>{Numerology.getChallengeNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth(),new Date(localStorage.dob).getFullYear(),(new Date(localStorage.dob).getFullYear()+(Number(pinnacleYear[pinnacleYear.length-1])+5)))}</td></tr>
                        </tbody>
                        </table>
                        </div>
                        <div className="col-md-5 info">
                            {/* <PersonalDayMonthYear dd={new Date(Number(native.dob)).getDate().toString()} mm={new Date(Number(native.dob)).getMonth().toString()} year={new Date(Number(native.dob)).getFullYear().toString()} /> */}
                            <PersonalDayMonthYear dd={new Date(native.dob).getDate().toString()} mm={new Date(native.dob).getMonth().toString()} year={new Date(native.dob).getFullYear().toString()} />
                        </div>
                    </div>
            }
            </div>
    )
}

export default ChartAnalysis;
