import React from 'react'
import * as Numerology from './calc/Numerology';
function Marriage() {
    return (
        <div>
            <h3 className="heading">Marriage & Relationship Analysis</h3>
            <div className="info preds">
            <p>It is said that marriages are made in heaven and we just execute the decision of almighty God. We believe in this but do not believe in the process of finding the possible partner. The God has made a perfect pair for everyone and we need to choose the right option to find it. With the help of Numerology, we will be able to find the correct partner and have the required balance. Relation with spouse is the most important aspect of a married life. If they both are compatible to each other then they can enjoy the ultimate bliss in life and if not then they will be forced to live a compromised life.</p>
            <p>As per your Birthday & Lifepath Number</p>
            <p>
                {Numerology.getMarriage(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1])}<br />
                {/* {Numerology.getMarriage(Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1,new Date(localStorage.dob).getFullYear())[1])}<br /> */}
                
            </p>
            <p>Marriage is a union of two souls. They may have the same traits or entirely different ones. To get the proper marriage analysis, you need to go for a detailed prediction for both the persons.  </p>
            </div>
        </div>
    )
}

export default Marriage
