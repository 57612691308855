import React from 'react'
import * as Numerology from './calc/Numerology';
function ImportantYears() {
    const career = Numerology.getImportantCareerYear(localStorage.dob);
    const finance = Numerology.getImportantFinanceYear(localStorage.dob);
    const family = Numerology.getImportantFamilyYear(localStorage.dob);
    return (
        <div>
            <h3 className="heading">Important Years in your life as per Family, Career & Finance.</h3>
            <div className="info preds">
            <table className="table table-stripped table-bordered">
                <tbody>
                    <tr>
                        <th>Family</th>
                        {/* <td>{family.map(x=>`[${x}] `)}</td> */}
                        <td>{family.join(', ')}</td>
                    </tr>
                    <tr>
                        <th>Career</th>
                        {/* <td>{career.map(x=>`[${x}] `)}</td> */}
                        <td>{career.join(', ')}</td>
                    </tr>
                    <tr>
                        <th>Finance</th>
                        {/* <td>{finance.map(x=>`[${x}] `)}</td> */}
                        <td>{finance.join(', ')}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    )
}

export default ImportantYears
