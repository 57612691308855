import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChangePassword from './ChangePassword';
import Dashboard from './components/Dashboard';
import ErrorBoundry from './components/ErrorBoundry';
import MatchMaking from './components/MatchMaking';
import NameCorrection from './components/NameCorrection';
import UserProfile from './components/UserProfile';
import EditProfile from './EditProfile';
import Login from './Login';
import NewUser from './NewUser.jsx';
import Signout from './Signout';
import Admin from './components/Admin';
import Site from './site/index';

function App() {

  return (
    <div>
      <ErrorBoundry>
       <Router>
         <Switch>
           {/* <Route exact path='/' component={Site} /> */}
           {/* <Route exact path='/' component={Dashboard} /> */}
            <Route exact path='/' component={Login} />
            <Route exact path='/newuser' component={sessionStorage.getItem('uid')===null ? Login : NewUser} />
            <Route exact path='/compatibility' component={sessionStorage.getItem('uid')===null ? Login : MatchMaking} />
            <Route exact path='/changepassword' component={sessionStorage.getItem('uid')===null ? Login : ChangePassword} />
            <Route exact path='/name' component={sessionStorage.getItem('uid')===null ? Login : NameCorrection} />
            <Route exact path='/user-profile' component={sessionStorage.getItem('uid')===null ? Login : UserProfile} />
            <Route exact path='/edit-profile' component={sessionStorage.getItem('uid')===null ? Login : EditProfile} />
            <Route exact path='/admin' component={sessionStorage.getItem('uid')===null ? Login : Admin} />
            <Route exact path='/signout' component={sessionStorage.getItem('uid')===null ? Login : Signout} />
           {/* <Route exact path='/dashboard' component={sessionStorage.getItem("uid")===null ? Login : Dashboard} />  */}
         </Switch>
       </Router>
       </ErrorBoundry>
    </div>
  )
}

export default App
