import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars'
// import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import ChartAnalysis from './ChartAnalysis';
import ImportantYears from './ImportantYears';
import NumberAnalysis from './NumberAnalysis';
import Personality from './Personality';
import PythagoreanCareer from './PythagoreanCareer';
import PythagoreanName from './PythagoreanName';
import YourNumbers from './YourNumbers';
function Pythagorean() {
    const [page, setPage]=useState('personality');
    const renderSwitch=()=>{
        switch(page){
            case 'personality':
                return <Personality />
            case 'chart':
                return <ChartAnalysis />
            case 'name':
                return <PythagoreanName />
            case 'years':
                return <ImportantYears />
            case 'your_number':
                return <YourNumbers />
        } 
    }
    // const native = useSelector((state: any) => state.nativeReducer);
    return (
        <div>
            <aside className="col-md-2 leftmenu">
                <h5 className="linkstyle" onClick={()=>setPage('personality')}>Personality</h5>
                <h5 className="linkstyle" onClick={()=>setPage('chart')}>Chart Analysis</h5>
                <h5 className="linkstyle" onClick={()=>setPage('your_number')}>Your Numbers</h5>
                {/* <h5 className="linkstyle" onClick={()=>setPage('personal')}>Personal Year / Month / Day</h5> */}
                <h5 className="linkstyle" onClick={()=>setPage('name')}>Name Analysis</h5>
                <h5 className="linkstyle" onClick={()=>setPage('years')}>Important Years in Your Life</h5>
            </aside>
            <aside className="col-md-10 contents">
                { 
                 renderSwitch()
                }
            </aside>
        </div>
    )
}

export default Pythagorean
