import React, { useState } from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { checkLogin } from './components/actions/User.js';
import Dashboard from './components/Dashboard';
import Footer from './components/Footer';
import Header from './components/Header';
function Login() {
    const user=useSelector((state:any)=>state.userReducer);
    const dispatch=useDispatch();
    // var macaddress = require('macaddress');
    const [email, setEmail]=useState('')
    const [password, setPassword]=useState('')
    const handleSubmit=()=>{
        dispatch(checkLogin(email, password))
        // dispatch(checkLogin())
        // dispatch(checkLogin({email, password}))
    }
    return (
      sessionStorage.getItem('uid')!==null ? <Dashboard /> :
        <>
        <Header />
        <div>
        <div className="login-box">
        <div className="login-logo">
          <a href="../../index2.html" style={{color:'#FF383F', WebkitTextStroke:'1px #fff'}}><b>Authentic</b>Astro {
            // macaddress.all(function (err:any, all:any) {
            //     <span>Mac address for this host: {all}</span>  
            //   })
          }</a>
        </div>
        
        <div className="login-box-body" style={{boxShadow:'2px 2px 5px 1px rgba(0, 0, 0, 0.2)', borderRadius:20}}>
          <p className="login-box-msg" data-testid="login-msg">Please Sign In</p>
      {sessionStorage.getItem('error')!==null ? <h3 className="alert alert-danger">{sessionStorage.getItem('error')}</h3> : null}
          <form onSubmit={(e)=>{e.preventDefault(); handleSubmit();}}>
            <div className="form-group has-feedback">
              <input type="email" className="form-control"value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            </div>
            <div className="form-group has-feedback">
              <input type="password" className="form-control" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div className="row">
              <div className="col-xs-8">
                <div className="checkbox icheck">
                  {/* <label>
                    <input type="checkbox" /> Remember Me
                  </label> */}
                </div>
              </div>
             
              <div className="col-xs-4">
                <button type="submit" className="btn btn-primary btn-block btn-flat">Sign In</button>
              </div>
             
            </div>
          </form>
      
          {/* <div className="social-auth-links text-center">
            <p>- OR -</p>
            <a href="#" className="btn btn-block btn-social btn-facebook btn-flat"><i className="fa fa-facebook"></i> Sign in using
              Facebook</a>
            <a href="#" className="btn btn-block btn-social btn-google btn-flat"><i className="fa fa-google-plus"></i> Sign in using
              Google+</a>
          </div> */}
          
      
          <a href="#">I forgot my password</a><br />
          {/* <a href="register.html" className="text-center">Register a new membership</a> */}
      
        </div>
        
      </div>
      </div>
      <Footer />
      </>
    )
}

export default Login
