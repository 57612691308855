import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Chaldean from './Chaldean'
import Dasha from './Dasha.jsx'
import Health from './Health'
import Loshu from './Loshu'
import Marriage from './Marriage'
import MatchMaking from './MatchMaking'
import NumerologistAdvice from './NumerologistAdvice'
import Prashan from './Prashan'
import Pythagorean from './Pythagorean'
import PythagoreanCareer from './PythagoreanCareer'
import Remedy from './Remedy'
import Mobile from './NumberAnalysis'
import ReactPDF from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
// import { PDFViewer } from '@react-pdf/renderer'
import GeneratePDF from './GeneratePDF'
import PrintPrediction from './PrintPrediction'

function Prediction() {
    return (
        <div>
            <Tabs style={{ padding: 0 }}>
              <TabList>
                {/* <Tab>Match Making</Tab> */}
                <Tab>Pythagorean</Tab>
                <Tab>Chaldean</Tab>
                <Tab>Loshu Grid</Tab>
                <Tab>Dasha</Tab>
                <Tab>Health</Tab>
                <Tab>Mobile Number Analysis</Tab>
                <Tab>Career</Tab>
                <Tab>Marriage</Tab>
                <Tab>Prashna</Tab>
                <Tab>Numerologist Advice</Tab>
                <Tab>Remedy</Tab>
                <Tab>Print</Tab>
              </TabList>
              {/* <TabPanel style={{ padding: 10 }}>
                <MatchMaking />
                </TabPanel> */}
              <TabPanel style={{ padding: 10}}>
                <Pythagorean />
              </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Chaldean />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Loshu />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Dasha />
                {/* <PDFViewer> */}
                {/* {ReactPDF.render(<GeneratePDF />, `${__dirname}/example.pdf`)} */}
                {/* {ReactPDF.renderToStream(<GeneratePDF />)} */}
                  {/* </PDFViewer> */}
                  {/* <PDFViewer>
    <GeneratePDF />
  </PDFViewer> */}
  
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Health />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Mobile/>
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <PythagoreanCareer />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Marriage />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Prashan />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                {/* Expert Advice */}
                <NumerologistAdvice />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
                <Remedy />
                </TabPanel>
              <TabPanel style={{ padding: 10 }}>
              <PrintPrediction />
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default Prediction
