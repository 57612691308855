import React, {useState} from 'react'
import * as Numerology from './calc/Numerology';
import {useSelector} from 'react-redux'
function Dasha() {
    const [antar, setAntar]=useState([]);
    const [planet, setPlanet]=useState(0);
    const native=useSelector((state)=>state.nativeReducer);
    const m=Numerology.getDasha(native.dob);
    const getAntarDasha=(s,e, i)=>{
        setPlanet(i);
        let sd=new Date(s).getTime();
        let ed=new Date(e).getTime();
        let td=(ed-sd)/86400000;
        let ttd=(td/45);
        console.log(ttd);
        // setAntar(Numerology.getAntarDasha(s,e,i,native.dob))
        console.log(new Date(sd+86400000).toDateString(),'=>',new Date(ed).toDateString(),'=>',i);
        let ssd=sd;
        let arr=[sd];
        while(ssd<ed){

            // arr.push((ssd+(ttd*Numerology.singleDigit(i)[1])*86400000)<=ed ?(ssd+(ttd*Numerology.singleDigit(i)[1])*86400000) : ed);
            arr.push((ssd+(ttd*Numerology.singleDigit(i)[1])*86400000));
            ssd+=(ttd*Numerology.singleDigit(i)[1])*86400000;
            i+=1;
        }
        setAntar(arr);
    }
    return (
        <div>
            <div className="col-md-5 info">
                <h3>Maha Dasha</h3>
                <table style={{border:'1 #ccc solid'}} className="table table-bordered">
                    <thead><tr><th>Number</th><th>Start</th><th>End</th><th className='onlyscreen'>Action</th></tr></thead>
                    <tbody>
                        {/* {m.map((x:string,i:number)=>{return <tr><td>{i===0 ? x : m[i-1]}</td><td>{i===0 ? m[i+1] : x}</td></tr>})} */}
                        {m.map((x,i)=>{return i < m.length-1 && <tr><td>{Numerology.singleDigit(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1]+i)[1]}</td><td>{x}</td><td>{m[i+1]}</td><td className='onlyscreen'><button className="btn btn-info" onClick={()=>getAntarDasha(x, m[i+1],Numerology.singleDigit(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1]+i)[1])}>Show</button></td></tr>})}
                    </tbody>
                </table>
                
            </div>
            <div className="col-md-6 info">
                <h3>Antar Dasha</h3>
                {/* {new Date(new Date().setDate(new Date().getDate()+1)).toDateString()} */}
                <table style={{border:'1 #ccc solid'}} className="table table-bordered">
                    <thead><tr><th>Number</th><th>Start</th><th>End</th></tr></thead>
                    <tbody>
                {antar.map((x,i)=>i<antar.length-1 && <tr><td>{Numerology.singleDigit(planet+i)[1]}</td><td>{new Date(x).toDateString()}</td><td>{new Date(antar[i+1]).toDateString()}</td></tr>)}
                </tbody>
                </table>
            </div>
        </div>
    )
}

export default Dasha
