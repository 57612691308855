import React from 'react'
import * as Numerology from './calc/Numerology';
function Remedy() {
    const d=Numerology.getRemedyNumbers(localStorage.dob);
    return (
        <div>
            <h3 className="heading">Remedy</h3>
            <div className="info preds">
            In order to align our numbers with our desires we need to perform certain pujas and wear suitable gems and stones. This helps us to multiply our wishes to make it happen early and dispel bad events.
            <ul>
            {d.map((x,i)=><li key={i}>{x+'=>'+Numerology.getRemedy(x)}</li>)}
            </ul>
            </div> 
        </div>
    )
}

export default Remedy
