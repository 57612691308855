import React from 'react'

function Popup(props:any) {
    return (
        <div>
            <div>
     <div className="modal_content">
     <h3 className="popup-heading">Authentic Astro <span className="close" onClick={()=>props.close()}>X</span></h3>
     
     <div className="preds" dangerouslySetInnerHTML={{__html: props.contents}} />
     {/* <p>{props.contents}</p> */}
    </div>
   </div>
        </div>
    )
}

export default Popup
