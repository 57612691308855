import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import NewProfile from './NewProfile'
import ProfileList from './ProfileList'

function Default() {
    return (
        <div>
            <Tabs style={{ padding: 0 }}>
              <TabList>
                <Tab>New</Tab>
                <Tab>List</Tab>
              </TabList>
              <TabPanel style={{ padding: 10}}>
              <NewProfile />
              </TabPanel>
              <TabPanel style={{ padding: 10 }}>
              <ProfileList />
                </TabPanel>
              
            </Tabs>
            
        </div>
    )
}

export default Default
