import React, {useState} from 'react'
import * as Numerology from './calc/Numerology.js';
function YearAnalysis(props:any) {
    const [year, setYear]=useState(Number(props.year));
    const [currYear, setCurrYear]=useState(new Date().getFullYear()-Number(year)>3 ? new Date().getFullYear()-3 : Number(year));
    console.log(year);
    const years=year>=0 ? Array.from(new Array(120),(val, index)=>index+Number(year)) : [];
    const chcycle=Array.from(new Array(5),(val, index)=>Numerology.getChallengeNumber(Number(props.dd),Number(props.mm),Number(props.year),currYear+index));
    const pncycle=Array.from(new Array(5),(val, index)=>Numerology.getPinnacleNumber(Number(props.dd),Number(props.mm),Number(props.year),currYear+index));
    const getEssenceCycle=(ans:number[])=>{
        return ans[0]>0 ? ans[0]+'/'+ans[1] : ans[1];
    }
    return (
        <div>
            <div className="sub-heading">Year Analysis</div>
            <button className="btn btn-danger col-md-3" onClick={()=>currYear>year && setCurrYear(currYear-1)}><strong>-</strong></button>
            <select className="col-md-6" value={currYear} onChange={(e)=>setCurrYear(Number(e.target.value))}>
                {
                    years.map((x)=><option key={x} value={x}>{x}</option>)
                }
            </select>
            <button className="btn btn-danger col-md-3" onClick={()=>setCurrYear(currYear+1)}><strong>+</strong></button>
            {/* {Numerology.getChallengeNumber(Number(props.dd),Number(props.mm),Number(props.year),currYear)} */}
                <table className="table table-striped preds" style={{fontSize:12}}>
                    <tbody>
                    <tr><td>Age</td><td>{currYear-year}</td><td>{currYear-year+1}</td><td>{currYear-year+2}</td><td>{currYear-year+3}</td><td>{currYear-year+4}</td></tr>
                    <tr><td>Year</td><td>{currYear}</td><td>{currYear+1}</td><td>{currYear+2}</td><td>{currYear+3}</td><td>{currYear+4}</td></tr>
                    <tr><td>Challenge Number</td><td>{chcycle[0]}</td><td>{chcycle[1]}</td><td>{chcycle[2]}</td><td>{chcycle[3]}</td><td>{chcycle[4]}</td></tr>
                    <tr><td>Pinnacle Number</td><td>{pncycle[0]}</td><td>{pncycle[1]}</td><td>{pncycle[2]}</td><td>{pncycle[3]}</td><td>{pncycle[4]}</td></tr>
                    <tr><td>First Name</td><td>{Numerology.getLetter(props.fname,props.year,currYear)}</td><td>{Numerology.getLetter(props.fname,props.year,currYear+1)}</td><td>{Numerology.getLetter(props.fname,props.year,currYear+2)}</td><td>{Numerology.getLetter(props.fname,props.year,currYear+3)}</td><td>{Numerology.getLetter(props.fname,props.year,currYear+4)}</td></tr>
                    <tr><td>Middle Name</td><td>{Numerology.getLetter(props.mname,props.year,currYear)}</td><td>{Numerology.getLetter(props.mname,props.year,currYear+1)}</td><td>{Numerology.getLetter(props.mname,props.year,currYear+2)}</td><td>{Numerology.getLetter(props.mname,props.year,currYear+3)}</td><td>{Numerology.getLetter(props.mname,props.year,currYear+4)}</td></tr>
                    <tr><td>Last Name</td><td>{Numerology.getLetter(props.lname,props.year,currYear)}</td><td>{Numerology.getLetter(props.lname,props.year,currYear+1)}</td><td>{Numerology.getLetter(props.lname,props.year,currYear+2)}</td><td>{Numerology.getLetter(props.lname,props.year,currYear+3)}</td><td>{Numerology.getLetter(props.lname,props.year,currYear+4)}</td></tr>
                    <tr><td>Essence Cycle</td><td>{getEssenceCycle(Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear)) }</td><td>{getEssenceCycle(Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+1)) }</td><td>{getEssenceCycle(Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+2)) }</td><td>{getEssenceCycle(Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+3)) }</td><td>{getEssenceCycle(Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+4)) }</td></tr>
                    <tr><td>Personal Year</td><td>{Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear)}</td><td>{Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+1)}</td><td>{Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+2)}</td><td>{Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+3)}</td><td>{Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+4)}</td></tr>
                    <tr><td>Total</td><td>{getEssenceCycle(Numerology.singleDigit(Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear)+Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear)[1]))}</td><td>{getEssenceCycle(Numerology.singleDigit(Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+1)+Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+1)[1]))}</td><td>{getEssenceCycle(Numerology.singleDigit(Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+2)+Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+2)[1]))}</td><td>{getEssenceCycle(Numerology.singleDigit(Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+3)+Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+3)[1]))}</td><td>{getEssenceCycle(Numerology.singleDigit(Numerology.getPersonalYear(Number(props.dd), Number(props.mm), currYear+4)+Numerology.getEssenceNumber(props.fname, props.mname,props.lname,props.year,currYear+4)[1]))}</td></tr>
                    </tbody>
                </table>
        </div>
    )
}

export default YearAnalysis
