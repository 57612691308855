import React, {useState, useRef} from 'react';
import Header from './Header';
import Footer from './Footer';
import DateDD from './DateDD';
import DateMM from './DateMM';
import DateYYYY from './DateYYYY';
import ReactToPrint from 'react-to-print';
import * as G from '../Global';
import * as Numerology from './calc/Numerology';
import { removeEmitHelper } from 'typescript';
function NameCorrection() {
    const namePrintRef=useRef();
    const months=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [calctype, setCalcType]=useState(1);
    const [date, setDate]=useState("01");
    const [fname, setFname]=useState('');
    const [mname, setMname]=useState('');
    const [lname, setLname]=useState('');
    const [month, setMonth]=useState(0);
    const [year, setYear]=useState(new Date().getFullYear().toString());
    const [fnamel, setFnameL]=useState([]);
    const [mnamel, setMnameL]=useState([]);
    const [lnamel, setLnameL]=useState([]);
    const updateDate=(v)=>{
        setDate(v);
    }
    const updateMonth=(v)=>{
        setMonth(v);
    }
    const updateYear=(v)=>{
        setYear(v);
    }
    const changeCalcType=(n)=>{
        console.log(n);
        setCalcType(n);
    }
    const NameList=fnamel.map((x,i)=><tr key={i}><td>{i+1}</td><td>{x} <span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(x)[1])[1]}</span> <span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(x)[1])[1]}</span></td><td>{mnamel[i] ? mnamel[i]: null} {mnamel[i] && <div><span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(mnamel[i])[1])[1]}</span> <span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(mnamel[i])[1])[1]}</span></div>}</td><td>{lnamel[i] ? lnamel[i]: null} {lnamel[i] && <div><span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(lnamel[i])[1])[1]}</span> <span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(lnamel[i])[1])[1]}</span></div>}</td>
    <td>{Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.pythagoreanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1]))]}
    {<div><span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1])[1]}</span></div>}
    </td>
    <td>{Numerology.Suitable[Numerology.getSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.chaldeanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1]))]}
    
    {<div><span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1])[1]}</span></div>}
    </td><td className='onlyscreen'><i className="material-icons" title="Delete Profile" style={{cursor:'pointer', color:'#c00', fontSize:22, paddingRight:10}} onClick={()=>removeMe(i)} >delete</i></td>
    </tr>)
    // const NameList=fnamel.map((x,i)=><tr key={i}><td>{i+1}</td><td>{x} <span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(x)[1])[1]}</span> <span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(x)[1])[1]}</span></td><td>{mnamel[i] ? mnamel[i]: null} {mnamel[i] && <div><span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(mnamel[i])[1])[1]}</span> <span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(mnamel[i])[1])[1]}</span></div>}</td><td>{lnamel[i] ? lnamel[i]: null} {lnamel[i] && <div><span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(lnamel[i])[1])[1]}</span> <span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(lnamel[i])[1])[1]}</span></div>}</td>
    // <td>{Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.pythagoreanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1]))]}
    // {<div><span className="btn btn-info btn-small">{Numerology.singleDigit(Numerology.pythagoreanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1])[1]}</span></div>}
    // </td>
    // <td>{Numerology.PythaSuitable[Numerology.getChaldeanSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.chaldeanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1]))]}
    
    // {<div><span className="btn btn-warning btn-small">{Numerology.singleDigit(Numerology.chaldeanCalc(fnamel[i]+mnamel[i]+lnamel[i])[1])[1]}</span></div>}
    // </td><td><i className="material-icons" title="Delete Profile" style={{cursor:'pointer', color:'#c00', fontSize:22, paddingRight:10}} onClick={()=>removeMe(i)} >delete</i></td>
    // </tr>)
    const removeMe=(i)=>{
        let f=fnamel.filter((x,index)=>index!==i);
        let m=mnamel.filter((x,index)=>index!==i);
        let l=lnamel.filter((x,index)=>index!==i);
        setFnameL(f);
        setMnameL(m);
        setLnameL(l);
        localStorage.setItem('fname_correction', f);
        localStorage.setItem('mname_correction', m);
        localStorage.setItem('lname_correction', l);
    }
    return (
        <div style={{backgroundColor:'#fff', minHeight:700}}>
            <Header />
            <h3 className="headings">Name Corrections </h3>
            <div className="info preds">
                <div className="col-md-2 col-sm-4 col-xs-12" style={{border:'0px #ccc solid'}}>
                    <label>Date of Birth</label>
                    <p className="clearfix"></p>
                    <div className="col-md-4">
                <DateDD value={date} updateDate={updateDate} />
                </div>
                <div className="col-md-4">
                <DateMM value={month} updateMonth={updateMonth} />
                </div>
                <div className="col-md-4">
                <DateYYYY value={year} updateYear={updateYear} />
                </div>
                </div>
                <div className="col-md-10">
                    {/* <div className="well text-center"> */}
                    {/* <input type="radio" value="1" name="ct" onChange={(e)=>{e.target.checked && changeCalcType(1)}} checked={calctype===1} /> Pythagorean &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio" value="2" name="ct" onChange={(e)=>{e.target.checked && changeCalcType(2)}} checked={calctype===2} /> Chaldean<br /> */}
                    {/* <select className="col-md-3 form-control" onChange={(e)=>{changeCalcType(e.target.value)}}>
                        <option value="1">Pythagorean</option>
                        <option value="2">Chaldean</option>
                    </select> */}
                    {/* <p className="clearfix"></p>
                    </div> */}
                    <div className="col-md-3">
                <input type="text" value={fname} onChange={(e)=>setFname(e.target.value)} />
                {/* <span>{fname!="" && calctype===1 && Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.pythagoreanCalc(fname)[1])[1])]}</span>
                <span>{fname!="" && calctype===2 && Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.chaldeanCalc(fname)[1])[1])]}</span> */}
                </div>
                <div className="col-md-3">
                <input type="text" value={mname} onChange={(e)=>setMname(e.target.value)} />
                {/* <span>Hello</span> */}
                </div>
                <div className="col-md-3">
                <input type="text" value={lname} onChange={(e)=>setLname(e.target.value)} />
                {/* <span>Hello</span> */}
                </div>
                {/* <span>{fname!="" && calctype===1 && Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.pythagoreanCalc(fname+mname+lname)[1])[1])]}</span>
                <span>{fname!="" && calctype===2 && Numerology.PythaSuitable[Numerology.getPythagoreanSuitable(Numerology.getBirthdayNumber(date)[1], Numerology.singleDigit(Numerology.chaldeanCalc(fname+mname+lname)[1])[1])]}</span> */}
                <div className="col-md-3" style={{textAlign:'center'}}>
                <button className="btn btn-primary" onClick={()=>{setFnameL([...fnamel, fname]);setMnameL([...mnamel, mname]);setLnameL([...lnamel, lname]);localStorage.setItem('fname_correction',[...fnamel, fname]); localStorage.setItem('mname_correction',[...mnamel, mname]); localStorage.setItem('lname_correction',[...lnamel, lname])}}>Add</button>
                </div>
                <p className="clearfix"></p>
                <div ref={namePrintRef}>
                    <h5 className='onlyprint heading'>Date of Birth: {date}-{months[month]}-{year}</h5>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Middle Name</th>
                            <th>Last Name</th>
                            <th>Pythagorean</th>
                            <th>Chaldean</th>
                            <th className='onlyscreen'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            NameList
                        }
                    </tbody>
                </table>
                </div>
                <ReactToPrint
        trigger={() => <button>Print Report!</button>}
        content={() => namePrintRef.current}
      />
                </div>
                {/* {new Date((year+'/'+months[month]+'/'+date).toString()).getTime()}
                {(year+'/'+month+1+'/'+date).toString()}
                {G.isValidDate(date,month,year)}
                {Date.parse('2021/02/31')} */}
            </div>
            <Footer />
        </div>
    )
}

export default NameCorrection
