import profileReducer from './profileReducer';
import nativeReducer from './nativeReducer';
import userReducer from './userReducer';
import {combineReducers} from 'redux'
const rootReducer=combineReducers
    (
        {
            profileReducer,
            nativeReducer,
            userReducer
        }
    );

export default rootReducer;