import React from 'react'
import { Link } from 'react-router-dom';

function Signout() {
    sessionStorage.clear();
    return (
        <div>
            <div className="login-box">
        <div className="login-logo">
          <a href="../../index2.html" style={{color:'#FF383F'}}><b>Authentic</b>Astro {
            // macaddress.all(function (err:any, all:any) {
            //     <span>Mac address for this host: {all}</span>  
            //   })
          }</a>
        </div>
        
        <div className="login-box-body" style={{boxShadow:'2px 2px #aaa 2px'}}>
          <p className="login-box-msg">You have successfully logged out.</p>
      
          <div style={{textAlign:'center'}}><Link to="/">Login</Link> Again</div>
      
        </div>
        
      </div>
            
            
        </div>
    )
}

export default Signout
