import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import React,{useState} from 'react';
import * as Numerology from './calc/Numerology';

function PersonalDayMonthYear(props:any) {
    const [year, setYear]=useState(Number(props.year));
    console.log(year);
    const [date, setDate]=useState(new Date());
    const [currYear, setCurrYear]=useState(new Date().getFullYear());
    const [personalYear, setPersonalYear]= useState(Numerology.getLifePathNumber(props.dd.toString(), (props.mm+1).toString(), date.getFullYear().toString())[1]);
    const years=year>=0 ? Array.from(new Array((new Date().getFullYear()+20)-year),(val, index)=>index+Number(year)): [];
    return (
        <div>
            <h3 className="heading">Personal Day Month Year</h3>
            
            <DatePickerComponent 
                    placeholder="Select Birth Date"
                    format="dd-MM-yyyy"
                    value={date}
                    onChange={(e:any)=>{console.log(e.target.value);e.target.value!==null ? setDate(e.target.value) : setDate(new Date()); setPersonalYear(Numerology.getLifePathNumber(props.dd.toString(), (props.mm+1).toString(), date.getFullYear().toString())[1])}}
                    ></DatePickerComponent>
                    <div className="col-md-6" style={{textAlign:'right'}}>Personal Year: {personalYear}</div>
                    <div className="col-md-3" style={{textAlign:'right'}}>Month: {Numerology.singleDigit((personalYear+(date.getMonth()+1)))[1]}</div>
                    <div className="col-md-3" style={{textAlign:'right'}}>Date: {Numerology.singleDigit(((Numerology.singleDigit((personalYear+(date.getMonth()+1)))[1]+date.getDate())))[1]}</div>
                    <table className="table table-stripped preds" style={{fontSize:12}}>
                        <thead>
                            <tr><th>Month</th><th>Personal Year</th><th>Personal Month</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>January</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+1))[1]}</td></tr>
                            <tr><td>February</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+2))[1]}</td></tr>
                            <tr><td>March</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+3))[1]}</td></tr>
                            <tr><td>April</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+4))[1]}</td></tr>
                            <tr><td>May</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+5))[1]}</td></tr>
                            <tr><td>June</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+6))[1]}</td></tr>
                            <tr><td>July</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+7))[1]}</td></tr>
                            <tr><td>August</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+8))[1]}</td></tr>
                            <tr><td>September</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+9))[1]}</td></tr>
                            <tr><td>October</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+10))[1]}</td></tr>
                            <tr><td>November</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+11))[1]}</td></tr>
                            <tr><td>December</td><td>{personalYear}</td><td>{Numerology.singleDigit((personalYear+12))[1]}</td></tr>
                        </tbody>
                    </table>
        </div>
    )
}

export default PersonalDayMonthYear
