import React from 'react';
class ErrorBoundry extends React.Component{
    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({hasError:true});
        console.log("Error: ",error, errorInfo);
      }
    
      render() {
        if (this.state.hasError) {
          // You can render any custom fallback UI
          return (<div style={{backgroundColor:'#fff'}}>
            <h3 className="heading">Something went wrong !</h3>
            <div className="info preds">There is an error, may be missing some data. please try again.<br /><span style={{textAlign:'center'}}><a href="https://numero.authenticastro.in">Refresh</a></span></div>
            </div>);
        }
    
        return this.props.children; 
      }
}
export default ErrorBoundry;