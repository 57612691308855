import { API_URL } from './../../Global';
import axios from "axios";
import Store from '../store/Store';
import * as nativeAction from './Native';
const getProfile=(clients:any[])=>{
    return {type: 'GET_PROFILE', clientList:clients}
}
const postProfile=(client:any)=>{
    // nativeAction.Dob(client.dob.toString());
    // nativeAction.Fname(client.fname.toString());
    // nativeAction.Mname(client.mname.toString());
    // nativeAction.Lname(client.lname.toString());
    return {type: 'POST_PROFILE', client:client}
}
export const getProfileData=()=>
()=>
axios.get(API_URL+'profile/read.php?uid='+sessionStorage.getItem('uid'))
.then(response => response.data)
.then(data=>Store.dispatch(getProfile(data)))
.catch(error=>console.log(error));

export const updateAdviceData=(id:string, adv:string)=>
()=>{

fetch(API_URL+'profile/updateadvice.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify({uid:id, advice:adv})
})
.then((response:any)=>response.data)
.then((data:any)=>Store.dispatch({type:'ADVICE', advice:adv}))
.catch(error=>alert(error));
//     axios.post(API_URL+'profile/insert.php',client)
// .then(response => response.data)
// .then(data=>Store.dispatch(postProfile(client)))
// .catch(error=>console.log(error));
}
export const postProfileData=(client:{})=>
()=>{
fetch(API_URL+'profile/insert.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify(client)
})
.then((response:any)=>response.data)
.then((data:any)=>Store.dispatch(postProfile(client)))
.catch(error=>console.log(error));
//     axios.post(API_URL+'profile/insert.php',client)
// .then(response => response.data)
// .then(data=>Store.dispatch(postProfile(client)))
// .catch(error=>console.log(error));
}
export const postProfileLogo=(client:any)=>
()=>{
  console.log("Post Logo", client);
fetch(API_URL+'user/imageupload.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      // 'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:client
})
.then((response:any)=>response.data)
// .then((data:any)=>Store.dispatch(postProfile(client)))
.catch(error=>console.log(error));
//     axios.post(API_URL+'profile/insert.php',client)
// .then(response => response.data)
// .then(data=>Store.dispatch(postProfile(client)))
// .catch(error=>console.log(error));
}

export const updateProfileData=(client:{})=>
()=>{
fetch(API_URL+'profile/update.php', 
{
    method:'POST',
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    referrerPolicy: 'no-referrer', 
    body:JSON.stringify(client)
})
.then((response:any)=>response.data)
.then((data:any)=>Store.dispatch(postProfile(client)))
.catch(error=>console.log(error));
//     axios.post(API_URL+'profile/insert.php',client)
// .then(response => response.data)
// .then(data=>Store.dispatch(postProfile(client)))
// .catch(error=>console.log(error));
}
export const changeProfile=(id:number)=>{
    axios.get(API_URL+'profile/read.php?id='+id+'&uid='+sessionStorage.getItem('uid'))
    .then(response => response.data)
    .then(data=>Store.dispatch({type:'CHANGE_PROFILE', profile:data}))
    .catch(error=>console.log(error));   
}