import React from 'react'

function Info() {
    return (
        <div>
            <table className="table table-bordered" style={{width:500, margin:'auto'}}>
                <tr>
                    <th>Name: </th>
                    <th>{localStorage.getItem('fname')+' '+localStorage.getItem('mname')+' '+localStorage.getItem('lname')}</th>
                </tr>
                <tr>
                    <th>Date of Birth: </th>
                    <th>{localStorage.getItem('dob')}</th>
                </tr>
                <tr>
                    <th>Gender: </th>
                    <th>{localStorage.getItem('gender')}</th>
                </tr>
                <tr>
                    <th>Mother: </th>
                    <th>{localStorage.getItem('mother')}</th>
                </tr>
                <tr>
                    <th>Father: </th>
                    <th>{localStorage.getItem('father')}</th>
                </tr>
                <tr>
                    <th>Contact: </th>
                    <th>{localStorage.getItem('contact')}</th>
                </tr>
                <tr>
                    <th>Email: </th>
                    <th>{localStorage.getItem('email')}</th>
                </tr>
                <tr>
                    <th>Address: </th>
                    <th>{localStorage.getItem('Address')}</th>
                </tr>
            </table>
        </div>
    )
}

export default Info
