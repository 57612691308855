import React, {useState} from 'react'
import * as Numerology from './calc/Numerology.js';
import {useSelector} from 'react-redux';
import PredPrint from './PredPrint';
import Popup from './Popup';
const chk=['b','l','k'];
// const chk=[];
function LoshuPlane() {
    const [isSeen, setIsSeen]=useState(false);
    const [page, setPage]=useState('grid');
    const [bn, setBN]=useState(true);
    const [ln, setLN]=useState(true);
    const [kua, setKua]=useState(true);
    const [contents, setContents]=useState("");
    
    const Native=useSelector((state)=>state.nativeReducer);
    const getTimes=(y)=>{
        // console.log(y,'=>',getClass(Number(y)[1]));
        let p1='';
        let p2='';
        grid.split('').map(x=>Number(x)===y ? p2=p2+`<span class="bn ${getClass(y)}">${x}</span>` : null);
        grid.split('').map(x=>Number(x)===y ? p1=p1+x : '');
        return [p1,p2];
    }
    const getClass=(n)=>{
        if(chk.includes("b") && n===Numerology.getBirthdayNumber(new Date(Native.dob).getDate())[1]){
            let i=chk.indexOf("b");
            chk.splice(i,1);
            return 'btn btn-info btn-circle';
        }else if(chk.includes("l") && n===Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]){
            let i=chk.indexOf("l");
            chk.splice(i,1);
            return 'btn btn-warning btn-circle';
        } else if(chk.includes("k") && n===Numerology.kuaNumber(new Date(Native.dob).getFullYear().toString(),Native.gender)){
            let i=chk.indexOf("k");
            chk.splice(i,1);
            return 'btn btn-danger btn-circle';
        } 
        return '';
    }
    let grid='';
    // grid=new Date(Number(Native.dob)).getDate().toString()+(new Date(Number(Native.dob)).getMonth()+1).toString()+new Date(Number(Native.dob)).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Number(Native.dob)).getDate().toString())+Numerology.getLifePathNumber(new Date(Number(Native.dob)).getDate().toString(),(new Date(Number(Native.dob)).getMonth()+1).toString(),new Date(Number(Native.dob)).getFullYear().toString())
    grid=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getBirthdayNumber(new Date(Native.dob).getDate().toString())[1]+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]+Numerology.kuaNumber(new Date(Native.dob).getFullYear(), Native.gender)
    // let planes=new Date(Native.dob).getDate().toString()+(new Date(Native.dob).getMonth()+1).toString()+new Date(Native.dob).getFullYear().toString()+Numerology.getLifePathNumber(new Date(Native.dob).getDate().toString(),(new Date(Native.dob).getMonth()+1).toString(),new Date(Native.dob).getFullYear().toString())[1]
    let planes=grid;
    console.log(new Date(Native.dob).getFullYear(), localStorage.getItem('gender'));
    // console.log(Numerology.getBirthdayNumber(new Date(Native.dob).getDate()));
    return (
        <div>
            <h3 className="heading">Loshu Plane</h3>
            <div className="info preds">
            <div className="col-xs-4">
                {/* {grid.split('').map(x=> console.log(getTimes(Number(x))[1]))} */}
                {Native.dob && Numerology.loshuGrid.map((x)=>x.map((y)=><div className='col-xs-4' style={{border:'1px #999 solid', height:100, textAlign:'center', padding:25, cursor:'pointer'}}>{grid.split('').includes(y.toString()) ? <span onClick={()=>{setContents(Numerology.getPreds(getTimes(y)[0], false));setIsSeen(true)}} dangerouslySetInnerHTML={{__html: getTimes(y)[1]}} /> : <span style={{color:'#ccc'}} onClick={()=>{setContents(Numerology.getPreds(y, true));setIsSeen(true)}}>{y}</span>}</div>))}
                {/* {Native.dob && Numerology.loshuGrid.map((x)=>x.map((y)=><div className='col-xs-4' style={{border:'1px #999 solid', height:100, textAlign:'center', padding:25, cursor:'pointer'}}>{grid.split('').includes(y.toString()) ? <span onClick={()=>{setContents(Numerology.getPreds(getTimes(y)[0], false));setIsSeen(true)}}> {getTimes(y)[1]} </span> : <span style={{color:'#ccc'}} onClick={()=>{setContents(Numerology.getPreds(y, true));setIsSeen(true)}}>{y}</span>}</div>))} */}
                <div style={{fontSize:12, paddingTop:10}}>
                <h5 className="btn btn-info">Birthdate Number: {Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1]}</h5><br />
                <h5 className="btn btn-warning">Lifepath Number: {Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1]}</h5><br />
                <h5 className="btn btn-danger">Kua Number: {Numerology.kuaNumber(new Date(Native.dob).getFullYear(), Native.gender)}</h5>
                </div>
            </div>
            <div className="col-xs-8">
            {/* {Numerology.getPythagoreanCombo(Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1], Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1])} */}
                {/* {Numerology.getAllNumbers(new Date(localStorage.dob).getDate(),new Date(localStorage.dob).getMonth()+1, (new Date(localStorage.dob).getFullYear().toString().split('')),Numerology.getBirthdayNumber(new Date(localStorage.dob).getDate())[1],Numerology.getLifePathNumber(new Date(localStorage.dob).getDate(), (new Date(localStorage.dob).getMonth()+1),new Date(localStorage.dob).getFullYear())[1]).map(x=><PredPrint number={x} prediction={Numerology.getPythagoreanCharacteristics(x)} />)} */}
                {/* <h4>All Missing Numbers</h4>
                {[1,2,3,4,5,6,7,8,9].filter((x)=>!grid.includes(x)).map(y=><PredPrint missing={true} number={y} prediction={Numerology.getLoshuMissing(y)}/>)}
                <span dangerouslySetInnerHTML={{__html: Numerology.getSmallArrow(grid)}} /> */}
            <div dangerouslySetInnerHTML={{__html: Numerology.getThoughtPlane(planes)}} />
            </div>
            <p className="clearfix"></p>
            <div dangerouslySetInnerHTML={{__html: Numerology.getWillPlane(planes)}} />
            <p className="pagebreak"></p>
            <div dangerouslySetInnerHTML={{__html: Numerology.getActionPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getMentalPlane(planes)}} />
            <p className="pagebreak"></p>
            <div dangerouslySetInnerHTML={{__html: Numerology.getEmotionalPlane(planes)}} />
            <div dangerouslySetInnerHTML={{__html: Numerology.getPracticlePlane(planes)}} />
            </div>
            {isSeen ? <Popup close={()=>setIsSeen(false)} contents={contents} /> : null}
        </div>
    )
}

export default LoshuPlane
