import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import React, { useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLanguageServiceSourceFile } from 'typescript';
import {postProfileData} from './actions/profile';
import * as Numerology from './calc/Numerology';
import * as Action from './actions/Native';
import { Redirect, useHistory } from 'react-router-dom';
import DateDD from './DateDD';
import DateMM from './DateMM';
import DateYYYY from './DateYYYY';

function NewProfile() {
const myState=useSelector((state:any)=>state.profileReducer);
const myNative=useSelector((state:any)=>state.nativeReducer);
const dispatch=useDispatch();
    // const [dob, setDob]=useState();
    const [date, setDate]=useState("01");
    const [month, setMonth]=useState(0);
    const [year, setYear]=useState(new Date().getFullYear().toString());
    const [fname, setFname]=useState('');
    const [mname, setMname]=useState('');
    const [lname, setLname]=useState('');
    const [father, setFather]=useState('');
    const [mother, setMother]=useState('');
    const [contact, setContact]=useState('');
    const [gender, setGender]=useState('');
    const [address, setAddress]=useState('');
    const [email, setEmail]=useState('');
    const dob=new Date(year+'-'+(Number(month)+1)+'-'+date).toString();
    let history=useHistory()
    const updateDate=(v:any)=>{
        setDate(v);
    }
    const updateMonth=(v:any)=>{
        setMonth(v);
    }
    const updateYear=(v:any)=>{
        setYear(v);
    }
    const makeData=()=>{
        // if(dob=='' || fname=='' || mname=='' || lname=='' || father=='' || mother=='' || contact=='' || gender=='' || address=='' || email==''){
        //     alert('All fields are mandetory');
        //     return false;
        // }
        let d=(year+'-'+(month+1)+'-'+date);
        let data={
            // 'dob':new Date(dob).toString(),
            'dob':dob,
            'fname':fname,
            'mname':mname,
            'lname':lname,
            'father':father,
            'mother':mother,
            'contact':contact,
            'gender':gender,
            'address':address,
            'email':email,
            'creatorid':sessionStorage.getItem('uid'),
        }
        console.log(d);
        console.log(data);
        return data;
    }
    const getDriverNumber=()=>{
        let ans=Numerology.getBirthdayNumber(new Date(dob).getDate().toString());
        console.log('Driver Number: ',ans[0],'/',ans[1]);
        getConductorNumber();
        getPythagoreanCalc(fname);
        getPythagoreanCalc(mname);
        getPythagoreanCalc(lname);
        getChaldeanCalc(fname);
        getChaldeanCalc(mname);
        getChaldeanCalc(lname);
        getExpression();
        getHeartDesire();

    }
    const getConductorNumber=()=>{
        let ans=Numerology.getLifePathNumber(new Date(dob).getDate().toString(), (new Date(dob).getMonth()+1).toString(),new Date(dob).getFullYear().toString());
        console.log('Conductor Number: ',ans[0],'/',ans[1]);
    }
    const getPythagoreanCalc=(name:string)=>{
        let num=Numerology.pythagoreanCalc(name);
        console.log('Pythagorean: ', name, num);
    }
    const getChaldeanCalc=(name:string)=>{
        let num=Numerology.chaldeanCalc(name);
        console.log('Chaldean: ', name, num);
    }
    const getExpression=()=>{
        let ans=Numerology.expressionNumber(fname,mname,lname);
        console.log('Expression Number: ',ans[0],'/',ans[1]);
    }
    const getHeartDesire=()=>{
        let ans=Numerology.heartDesireNumber(fname,mname,lname);
        console.log('Heart Desire Number: ', ans);
    }
    const update=(data:any)=>{
        console.log(data);
        dispatch(Action.Dob(new Date(dob).toString()));
        dispatch(Action.Fname(data.fname));
        dispatch(Action.Mname(data.mname));
        dispatch(Action.Lname(data.lname));
        dispatch(Action.Native(false));
        // history.push('/');
    }
    const handleSubmit=()=>{
        let _data=makeData();

        // if(_data!==false){
        fetch('http://localhost:8888/api/profile/insert.php', 
        {
            method: "POST",
            body: JSON.stringify(_data),
            headers: {"Content-type": "application/json; charset=UTF-8"}
        }
        )
        .then(response => response.json()) 
.then(json => update(json))
.catch(err => console.log(err));
    // }
    }
    return (
        <div>
            <form onSubmit={(e)=>{e.preventDefault(); dispatch(postProfileData(makeData())); update(makeData());}}>
                <div className="form-group">
                    <div className="col-md-4">
                    <label>Birth Date</label><br />
                    <div className="col-md-4">
                <DateDD value={date} updateDate={updateDate} />
                </div>
                <div className="col-md-4">
                <DateMM value={month} updateMonth={updateMonth} />
                </div>
                <div className="col-md-4">
                <DateYYYY value={year} updateYear={updateYear} />
                </div>
                    {/* <DateTimePickerComponent 
                    placeholder="Select Birth Date"
                    format="dd-MM-yyyy HH:mm"
                    step={1}
                    value={dob}
                    onChange={(e:any)=>setDob(e.target.value)}
                    ></DateTimePickerComponent> */}
                    </div>
                    <div className="col-md-8">
                    <label>Gender</label><br />
                    <input type="radio" name="g" onChange={(e:any)=>setGender('Male')} checked={gender==='Male' ? true : false} /> Male 
                    <input type="radio" name="g" onChange={(e:any)=>setGender('Female')} checked={gender==='Female' ? true : false}  /> Female 
                    <input type="radio" name="g" onChange={(e:any)=>setGender('Other')} checked={gender==='Other' ? true : false}  /> Other
                    </div>
                    <br />
                    <div className="clearfix"></div>
                    <div className="col-md-4">
                    <label>First Name</label>
                    <input type="text" className="form-control" value={fname} onChange={(e:any)=>setFname(e.target.value)} />
                    </div>
                    <div className="col-md-4">
                    <label>Middle Name</label>
                    <input type="text" className="form-control" value={mname} onChange={(e:any)=>setMname(e.target.value)} />
                    </div>
                    <div className="col-md-4">
                    <label>Last Name</label>
                    <input type="text" className="form-control" value={lname} onChange={(e:any)=>setLname(e.target.value)} />
                    </div><br />
                    <div className="clearfix"></div>
                    
                    <div className="col-md-6">
                    <label>Mother Name</label>
                    <input type="text" className="form-control" value={mother} onChange={(e:any)=>setMother(e.target.value)}  />
                    </div>
                    <div className="col-md-6">
                    <label>Father Name</label>
                    <input type="text" className="form-control" value={father} onChange={(e:any)=>setFather(e.target.value)}  />
                    </div><br />
                    <div className="col-md-6">
                    <label>Contact</label>
                    <input type="text" className="form-control" value={contact} onChange={(e:any)=>setContact(e.target.value)}  />
                    </div>
                    <br />
                    {/* <div className="clearfix"></div> */}
                    <div className="col-md-6">
                    <label>Email</label>
                    <input type="email" className="form-control" value={email} onChange={(e:any)=>setEmail(e.target.value)}  />
                    </div>
                    <div className="col-md-12">
                        <label>Address</label>
                        <input type="text" className="form-control" value={address} onChange={(e:any)=>setAddress(e.target.value)}  />
                    </div>
                    <p className="clearfix"></p>
                    <p className="clearfix"></p>
                    <p className="clearfix"></p>
                    <input type="submit" className="btn btn-primary" style={{float:'right', marginRight:20}}  />
                    {/* <button className="btn btn-warning" onClick={()=>getDriverNumber()} value="DriverNumber" /> */}
                    <p className="clearfix"></p>
                    <p className="clearfix"></p>
                </div>
                
                </form> 
        </div>
    )
}

export default NewProfile
