import React, {useEffect, useState} from 'react';
import Datatable from 'react-data-table-component';
import {useSelector, useDispatch} from 'react-redux';
import {getProfileData} from './actions/profile';
import * as Action from './actions/Native';
import { API_URL } from './../Global';
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Store from './store/Store';
import { Redirect, useHistory } from 'react-router-dom';

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        backgroundColor:'#123',
        color:'#fff',
        fontWeight:700,
        fontSize:14
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };
function ProfileList() {
    const profile=useSelector((state:any)=>state.profileReducer);
    // const native=useSelector((state:any)=>state.nativeReducer);
    // let history=useHistory();
    const update=(data:any)=>{
      console.log(data);
      dispatch(Action.Id(data[0].id));
      dispatch(Action.Dob(data[0].dob));
      dispatch(Action.Fname(data[0].fname));
      dispatch(Action.Mname(data[0].mname));
      dispatch(Action.Lname(data[0].lname));
      dispatch(Action.Gender(data[0].gender));
      dispatch(Action.Advice(data[0].advice));
      dispatch(Action.Native(false));
      // history.push('/');
  }
    const getData=(id:number)=>{
      console.log(API_URL+'profile/read.php?id='+id+'&uid='+sessionStorage.getItem('uid'));
axios.get(API_URL+'profile/read.php?id='+id+'&uid='+sessionStorage.getItem('uid'))
.then(response => response.data)
.then(data=>update(data))
.catch(error=>console.log(error));
    }
    const deleteData = (id:number) => {
      confirmAlert({
        title: 'Authentic Astro',
        message: 'Are you sure to do this, will not be able to rollback.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {console.log(API_URL+'profile/delete.php?id='+id+'&uid='+sessionStorage.getItem('uid'));
            axios.get(API_URL+'profile/delete.php?id='+id+'&uid='+sessionStorage.getItem('uid'))
            .then(response => response.data)
            .then(data=>{alert(data);dispatch(getProfileData())})
            .catch(error=>console.log(error));
                  }
          },
          {
            label: 'No',
            onClick: () => null
          }
        ]
      });
    };
//     const deleteData=(id:number)=>{
//       if(confirm("Sure to delete, will not be able to undo.")){
//       console.log(API_URL+'profile/delete.php?id='+id+'&uid='+sessionStorage.getItem('uid'));
// axios.get(API_URL+'profile/delete.php?id='+id+'&uid='+sessionStorage.getItem('uid'))
// .then(response => response.data)
// .then(data=>{console.log(data);update(data)})
// .catch(error=>console.log(error));
//       }
//     }
    const dispatch=useDispatch();
    const [search, setSearch]=useState('');
    const coloumn=[
      {
        name:'Creator',
        selector:'creatorname'
    },  
      {
            name:'Name',
            selector:'name',
            sortable:true,
            cell: (row:any)=>row.fname+" "+row.mname+" "+row.lname
        },
        {
            name:'Date Of Birth',
            selector:'dob'
        },
        {
            name:'Contact',
            selector:'contact'
        },
        {
          name:'Gender',
          selector:'gender'
        },
        {
            name:'Actions',
            cell: (row:any)=><>
            <i className="material-icons" title="Use Profile" style={{cursor:'pointer', color:'#0c0', fontSize:22, paddingRight:10}} onClick={()=>getData(row.id)}>check_circle</i> 
            <i className="material-icons" title="Edit Profile" style={{cursor:'pointer', color:'#3C8DED', fontSize:22, paddingRight:10}} onClick={()=>alert(row.id)}>manage_accounts</i> 
            <i className="material-icons" title="Delete Profile" style={{cursor:'pointer', color:'#c00', fontSize:22, paddingRight:10}} onClick={()=>deleteData(row.id)}>delete</i>
            </>
        }
    ];
    useEffect(()=>{
      dispatch(getProfileData())
    },[]);
    return (
        <div>
            <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Enter Search Text" style={{width:300, borderRadius:10, height:30, marginTop:5}} />
            <Datatable
            style={{border:'1px #ccc solid', marginTop:-35}}
            columns={coloumn}
            customStyles={customStyles}
            pagination={true}
            data={profile.clients.filter((x:any)=>x.fname.includes(search) || x.mname.includes(search) || x.lname.includes(search) || x.contact.includes(search) || x.dob.includes(search))}
            />
        </div>
    )
}

export default ProfileList
